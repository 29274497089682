import axios from "axios";
import { appConstants } from "../constants/appConstants";


function setCookie(id) {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/setCookie`).then(data => {
                console.log(data);
                const d = data.data;
                console.log(d)
                resolve1(d)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

function getCookie(id) {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/getCookie`).then(data => {
                console.log(data);
                const d = data.data;
                console.log(d)
                resolve1(d)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}


function adminLogin(username, password, email){
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.post(`${appConstants.BASE_URL}/adminLogin`, {"username":username, "password":password, "email":email}).then(data => {
                console.log(data);
                const {message} = data.data;
                console.log(message)
                if(message==="valid"){
                    resolve1("valid");
                }
                else{
                    reject1("not valid")
                }
            }).catch(err => {
                reject1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })
}

function verifyAdmin(pinCode, username, email) {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.post(`${appConstants.BASE_URL}/verifyAdmin`, { "username": username, "pinCode": pinCode, "email": email }).then(data => {
                console.log(data);
                const { cookie } = data.data;
                if (cookie) {
                    resolve1(cookie);
                }
                else {
                    reject1("not valid")
                }
            }).catch(err => {
                reject1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })
}

const adminServices = {
    setCookie,
    getCookie,
    adminLogin,
    verifyAdmin
}

export default adminServices