import React, { useState, useEffect, useRef, useContext } from 'react';
// import {helloServices} from "../../services/helloServices";
import { useTranslation } from 'react-i18next';
import backgroundImg from "../../assets/images/Re-design assets/Copy of Landing page image cropped.png";

function Landing() {
    const { t, i18n } = useTranslation();
    return (
        <div className='img-wrap height-100-vh'>
            <div className='background-color-for-image'></div>
            <img src={backgroundImg} alt="background flourish access website" className="flourish-background-img-landing" />
            <span className='landing-page-text'>{t(`Flourish aims to address the root causes of gender-based violence(GBV) by engaging, empowering and supporting women affected by female genital mutilation/cutting (FGM/C)through the systematic application of gender equity, trauma-informed, culturally sensitive and safe caring strategies.`)}</span>
        </div>
    );
}

export default Landing;