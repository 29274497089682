import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { ReactDOM } from "react";
import "./podcast.css";
import mysqlServices from "../../../services/mysqlServices";

function Podcast(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();

    const [podcasts, setPodcasts] = useState(null);


    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getPodcasts().then(data => {
                let podcasts = data.data;
                if (!podcasts) { return }
                //console.log(podcasts)
                setPodcasts(podcasts)
            }).catch(err => {
                console.log(err);
            })
        } else {

        }
    }, [])


    return (
        <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-1-rem column-gap-1-rem">
            <span className='font-size-22-px bold'>{t("resources-page.podcast.header")}</span>
            <span className=''>{t("resources-page.podcast.text")}</span>
            <div className="display-flex flex-direction-column column-gap-2-rem row-gap-2-rem">
                {/* <span className="color-grey">
                    {"Coming Soon ..."}
                </span> */}
                {/* {renderMarkdown(mInput, "george")} */}

                {podcasts != null && podcasts.length > 0 ? podcasts.map(podcast =>
                    <div>
                        <div>{i18n.language === "fr" ? podcast.title_french : podcast.title_english}</div>
                        <div className="Podcast-Player"><iframe className="Podcast-Iframe" frameborder="no" scrolling="no" seamless src={podcast.src}></iframe></div>
                        <span>{i18n.language === "fr" ? podcast.summary_french : podcast.summary_english}</span>
                    </div>
         
                ) : null}
          

           
            </div>
        </div>
    )
}
export default Podcast;