import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "../../tools.component/youtubeEmbed.component";
import "./digital-stories.css";
import personalNarrativePDF from "../../../assets/pdf/Personal Narratives on Female Genital Mutilation - Major Themes from Digital Stories.pdf";
import mysqlServices from "../../../services/mysqlServices";

function DigitalStories(params) {
    const loaded = useRef(false);
    const { t, i18n } = useTranslation();

    const [digitalStories, setDigitalStories] = useState([]);


    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            mysqlServices.getDigitalStories().then(data => {
                let stories = data.data;
                if (!stories) { return }
                setDigitalStories(stories)
            }).catch(err => {
                console.log(err);
            })
        }
    }, [])

    return (
        <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-2-rem column-gap-2-rem">

            <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-0-5-rem column-gap-0-5-rem">
                <span className="bold font-size-24-px">{t("resources-page.digital-stories.header1")}</span>
                <span className="">{t("resources-page.digital-stories.text1")}</span>
                <span className="cursor-pointer hover-background-color-light-pink width-fit" onClick={() => { window.open(personalNarrativePDF)}}><span className="bold">{t("resources-page.digital-stories.text2")}</span>{t("resources-page.digital-stories.text3")}</span>
            </div>

            <div className="display-flex flex-wrap row-gap-2-rem column-gap-2-rem width-100-percent">
                {digitalStories?.map((item, index) =>
                    <div key={`${index} digital stories`} className="display-flex flex-direction-column flex-wrap min-width-15-rem row-gap-0-5-rem column-gap-0-5-rem width-100-percent">
                        <span className="bold font-size-20-px">{i18n.language === "fr" ? item?.header_french : item?.header_english}</span>
                        <YoutubeEmbed title={i18n.language === "fr" ? item?.header_french : item?.header_english} embedId={i18n.language === "fr" && item?.link_french ? item?.link_french : item?.link_english} videoStyle="Video-Style" />
                    </div>
                )}
            </div>
            
        </div>
    )
}
export default DigitalStories;