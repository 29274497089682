import React, { useState } from "react";
import "./footer.css";
// import genernetImg from "../../assets/images/logos/Gender.Net_Plus 1.png";
import wageImg from "../../assets/images/logos/WAGE-FEGC-R-e (1).png";
import whiwhImg from "../../assets/images/logos/WHIWH_logo-_landscape_Large.png"
import PrivacyPolicy from "../privacyPolicy.component/privacyPolicy.component";
import leafImg from "../../assets/images/logos/leaf-CIHR-colour-portrait-en.png";
import gendernetNewImg from "../../assets/images/logos/RHCforFGC.png";

function Footer(props){
    const [showPrivacy, setShowPrivacy] = useState(false);

    const year = ()=> {
        let y = new Date();
        return y.getFullYear();
    }

    return (
        <>
        <div className="width-100 height-1-rem background-color-light-orange"></div>
        <div className="background-color-light-pink Footer-Container">
            <div className="parent-container-x-margins display-flex flex-direction-column row-gap-1-rem column-gap-1-rem">
                <div className="display-flex flex-direction-column flex-wrap row-gap-0-5-rem column-gap-0-5-rem">
                    <img src={whiwhImg} alt="gender net plus" className="width-15-rem" />
                    <span className="bold font-size-20-px">{"Thank You To Our Supporters"}</span>
                    <div className="display-flex flext-direction-row flex-wrap align-items-center row-gap-1-rem column-gap-1-rem">
                        <img src={wageImg} alt="gender net plus" className="width-15-rem"  />
                        <img src={gendernetNewImg} alt="women's health in women hand" className="width-10-rem"  />
                        <img src={leafImg} alt="gender net plus" className="width-10-rem" />
                    </div>
                </div>
                {/* <div className="display-flex flex-direction-column flex-wrap row-gap-0-5-rem column-gap-0-5-rem">
                    <span className="bold font-size-20-px">{"Funder"}</span>
                    <div className="display-flex flext-direction-row flex-wrap overflow-hidden">
                        <img src={wageImg} alt="women and gener, government of canada" className="width-15-rem"/>
                    </div>
                </div> */}
            </div>
        </div>
        <div className="padding-1-rem row-gap-0-5-rem column-gap-0-5-rem width-100 display-flex flex-direction-column justify-content-center background-color-dark-pink padding-0-5-rem">
            <span className="parent-container-x-margins bold cursor-pointer font-size-10-px width-fit" onClick={() => { setShowPrivacy(true)}}>Privacy</span>
            <PrivacyPolicy showModal={showPrivacy} setShowModal={setShowPrivacy} />
            <span className="parent-container-x-margins font-size-10-px">
                {`Copyright © ${year()} Women's Health in Women's Hands.`}
            </span>
        </div>
        </>

    );
}
export default Footer;