import React, { useEffect, useLocation, useRef, useState, useContext, useMemo } from 'react';
import * as ReactDOM from 'react-dom/client';
import { withRouter, useParams, useNavigate, Link } from 'react-router-dom';
import mapboxgl from "mapbox-gl";
import "./map.css"
import 'mapbox-gl/dist/mapbox-gl.css'; //this file is very imprtant for the map to adjust when zooming 
import mysqlServices from "../../services/mysqlServices";
import { IoMdPin } from "react-icons/io";
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import { AiFillCloseCircle } from "react-icons/ai";
import { AdminContext} from "../../App";
import Spinner from 'react-bootstrap/Spinner';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { GrFormClose } from "react-icons/gr";
import { useTranslation } from "react-i18next";

// import geoJson from "./chicago-parks.json";
mapboxgl.accessToken = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;


// const Marker = ({ map, onClick, onMouseIn, feature, setSelectedMarker, currentPoppup}) => {
//     const _onClick = () => {
//         setSelectedMarker(feature)
//         // if(currentPoppup.current !== null){
//         //     currentPoppup.current.togglePopup();
//         // }

//         const popup = new mapboxgl.Popup({ closeOnClick: false }).setLngLat([feature.lng, feature.lat])
//             .setHTML(html).addTo(map)
        
//         // onClick(feature);


//         // const marker = new mapboxgl.Marker({color:"red"})
//         //     .setLngLat([feature.lng, feature.lat])
//         //     .setPopup(new mapboxgl.Popup({ closeOnClick: false }).setHTML(html))
//         //     .addTo(map);
//         // marker.togglePopup()
//         // currentPoppup.current = marker
//     };

//     // const _onMouseIn = () => {
//     //     map.getCanvase().style.cursor = 'pointer';
//     //     setShowName(true)
//     //     // onMouseIn(feature)
//     // }

//     // const _onMouseLeave = () => {
//     //     setTimeout(() => { setShowName(false); }, 500)
        
//     // }
    
//     // const [showName, setShowName] = useState(false);

//     // const [selected, setSelected] = useState(false);
    
//     let phoneNumbers = [];
//     let phoneDiv = `<div class='display-flex column-gap-0-5-rem'>
//                         <p class="margin-5-px">Phone </p><div class="">`
//     if (feature.phone){
//         phoneNumbers = feature.phone.split("%");
//         phoneNumbers.map(item => {
//             phoneDiv += `${item}\n`
//         })
//     }
//     phoneDiv +=`</div></div>`;
//     //less than two because by default we have "" in the database
//     if (phoneNumbers.length < 1 || (phoneNumbers.length === 1 && phoneNumbers[0] === "")) {
//         phoneDiv = "";
//     }

//     let services = [];
//     let filterDiv = `<div class='display-flex column-gap-0-5-rem'>
//                         <p class="margin-5-px">Services </p><div class="">`
//     if(feature.filters){
//         services = feature.filters.split("%");
//         services.map(item => {
//             filterDiv += `<p class="background-color-light-pink width-fit border-radius-5-px padding-2-px margin-5-px">${item}</p>`
//         })
//     }

//     filterDiv += `</div></div>`;
//     if (services.length < 1 || (services.length === 1 && services[0] === "")) {
//         filterDiv = "";
//     }

//     let violence = [];
//     let remarksDiv = `<div class='display-flex column-gap-0-5-rem'>
//                         <p class="margin-5-px">Type of violence </p><div class="">`

//     if(feature.remarks){
//         violence = feature.remarks.split("%");
//         violence.map(item => {
//             remarksDiv += `<p class="background-color-light-pink width-fit border-radius-5-px padding-2-px margin-5-px">${item}</p>`
//         })
//     }

//     remarksDiv += `</div></div>`;
//     if(violence.length < 1 || (violence.length===1 &&violence[0]==="")){
//         remarksDiv = "";
//     }


//     const html = (`
//                 <div class='Pin'>
//                     <div class='display-flex column-gap-0-5-rem margin-5-px'>
//                         <p class='bold margin-5-px'>${feature.name}</p>
//                     </div>

//                     <div class='display-flex column-gap-0-5-rem margin-5-px'>
//                         <p class="margin-5-px">${feature.street_number}, ${feature.street_name},${feature.suite !=="" ? " "+feature.suite+"," : ""} ${feature.city}, ${feature.province}, ${feature.postal_code}</p>
//                     </div>

//                     ${(feature.website && feature.website !== "") ? 
//                         `<div class='display-flex column-gap-0-5-rem margin-5-px' >
//                         <p class="margin-5-px">Website </p>
//                         <a href=${feature.website} target="_blank" rel="noreferrer">${feature.website}</a>
//                         </div >`
//                     : ""}

//                     ${(feature.email && feature.email !== "") ? 
//                         `<div class='display-flex column-gap-0-5-rem margin-5-px' >
//                         <p class="margin-5-px">Email</p>
//                         <p class="margin-5-px">${feature.email}</p>
//                         </div >`
//                     : ""}

                    

//                     ${phoneDiv}
    
//                     ${remarksDiv}
              
//                     ${filterDiv}
           

//                     ${(feature.description !== "" && feature.description !== null) ?
//                         `<p>${feature.description}</p>` : ""
//                     }

//                 </div>`)


//     // const htmlAdmin = (`
//     //             <div class='Pin'>
                    
//     //                 <div class="display-flex">
//     //                 <input type="number" placeholder="lng" value=\"${feature.lng}" id=\"${feature.id+"lng"}">
//     //                 <input type="number" placeholder="lat" value=\"${feature.lat}" id=\"${feature.id + "lat"}">
//     //                 </div>

//     //                 <input type="text" placeholder="name" value=\"${feature.name}" onclick=handleChange('Success', "dsd") >
//     //                 "<button type='button' onclick=click('Success')>This Button</button>"
                    
//     //                 <div class="display-flex">
//     //                 <input type="text" placeholder="street number" value=\"${feature.street_number}" >
//     //                 <input type="text" placeholder="street name" value=\"${feature.street_name}" >
//     //                 <input type="text" placeholder="suite" value=\"${feature.suite}" >
//     //                 <input type="text" placeholder="city" value=\"${feature.city}" >
//     //                 <input type="text" placeholder="province" value=\"${feature.province}" >
//     //                 <input type="text" placeholder="postal code" value=\"${feature.postal_code}"
//     //                  >
//     //                 </div>

//     //                 <input type="text" placeholder="webiste" value=\"${feature.website}" >
//     //                 <input type="text" placeholder="phone" value=\"${feature.phone}" >
//     //                 <input type="text" placeholder="email" value=\"${feature.email}" >
//     //                 <input type="text" placeholder="remarks" value=\"${feature.remarks}" >
//     //                 <input type="text" placeholder="filters" value=\"${feature.filters}" >
//     //                 <input type="text" placeholder="description" value=\"${feature.description}" >

//     //             </div>
//     //             `)

//     return (
        
//         <div  className="" >
//             {/* {showName ? <p className='absolute width-10-rem top-negative-1-rem left-negative-1-rem'>{feature.name}</p> : null} */}
//             <span className="marker pin-hover-effect" onClick={_onClick} /*onMouseEnter={() => { _onMouseIn() }} onMouseLeave={() => { _onMouseLeave() }}**/>
//             <IoMdPin /> 
//             </span>

//             {/* {(adminLoggedIn && selected==="true") ? 
//                 <div className='Selected_Pin_Container'>

//                     <div class='Admin-Pin'>
//                         <div className='display-flex column-gap-0-5-rem'>
//                             <span className='cursor-pointer icons-close-color' onClick={() => { setSelected("false") }}>
//                                 <AiFillCloseCircle />
//                             </span>
//                         </div>

//                         <div class="display-flex">
//                             <input type="number" placeholder="lng" value={pin.name} onChange={()=> {handleChange("lng", "lng")}} />
//                             <input type="number" placeholder="lat" value={pin.name} />
//                         </div>

//                         <input type="text" placeholder="name" value={pin.name} onChange={(e) => { handleChange("name", e) }}  />

//                         <div class="display-flex">
//                             <input type="text" placeholder="street number" value={pin.street_number}/>
//                             <input type="text" placeholder="street name" value={pin.street_name} />
//                             <input type="text" placeholder="suite" value={pin.suite} />
//                             <input type="text" placeholder="city" value={pin.city} />
//                             <input type="text" placeholder="province" value={pin.province} />
//                             <input type="text" placeholder="postal code" value={pin.postal_code} />
//                         </div>

//                         <input type="text" placeholder="webiste" value={pin.website} />
//                         <input type="text" placeholder="phone" value={pin.phone} />
//                         <input type="text" placeholder="email" value={pin.email} />
//                         <input type="text" placeholder="remarks" value={pin.remarks} / >
//                         <input type="text" placeholder="filters" value={pin.filters} />
//                         <input type="text" placeholder="description" value={pin.description} / >

//                     </div>


//                 </div>
//                 : null
//             }
//              */}



//         </div>
             

//     );
// };









function MapFunction(props) {
    const adminState = useContext(AdminContext)

    /**
     * The useRef Hook allows you to persist values between renders.
        It can be used to store a mutable value that does not cause a re-render when updated.
        It can be used to access a DOM element directly.
        const count = useRef(0). It's like doing this: const count = {current: 0}.
     */
    const mapContainer = useRef(null);
    const map = useRef(null);
    const [lng, setLng] = useState(-73.620584);
    const [lat, setLat] = useState(45.529191);
    const [zoom, setZoom] = useState(4);
    const mapStyle = 'mapbox://styles/mapbox/streets-v11';


    const loaded = useRef(false);


    const [selectedPin, setSelectedPin] = useState(null);
    
    const [provinceFilter, setProvinceFilter] = useState("Select");
    const [violenceFilter, setViolenceFilter] = useState("Select");
    const [serviceFilter, setServiceFilter] = useState("Select");

    const { t } = useTranslation();

    const provinces = [
        "Select", "Québec", "Ontario", "British Columbia", "New Brunswick", "Newfoundland and Labrador", "Prince Edward Island", "Alberta", "Saskatchewan", "Manitoba", "Nova Scotia"
    ]
    const violenceTypes = [
        "Select", "Domestic Violence", "Sexual Violence", "Human Trafficking", "Female Genital Mutilation/Cutting"
    ]
    const services = [
        "Select", "Immigration and Settlement Support", "Legal Clinic", "Psychosocial Support", "Groups and Workshops", "Employment Support", "Housing services and Shelter", "Food Access", "Advocacy and Referrals", "Counselling", "Psychotherapy", "Crisis Services", "Primary Health Care", "Sexual and Reproductive Health", "Maternal and Child Health", "Reconstructive Surgery"
    ]




    // const [viewPort, setViewPort] = useState({
    //     width: "600px",
    //     height: "600px",
    //     latitude: 40.7250863,
    //     longitude: -73.9773608,
    //     zoom: 12
    // })

    const signOutStyle = { color: 'red', float: 'right' };
    const getLocationStyle = { backgroundColor: 'grey', position: 'relative', float: 'none' };
    var welcomeMessegeStyle = { color: 'green', float: 'right', marginRight: "60px" };

    const [allPins, setAllPins] = useState(null);
    // const [pins, setPins] = useState(null);
    const [markers, setMarkers] = useState([])
    const [selectedPins, setSelectedPins] = useState([])

    const [selectedMarker, setSelectedMarker] =  useState(null);

    const [spinnerOn, setSpinnerOn] = useState(false)

    useEffect(()=> {
        setAdminPin({...selectedMarker})
    }, [selectedMarker])

    const [adminPin, setAdminPin] = useState(null)

    const [errorMessage, setErrorMessage] = useState(null)

    const mapStyles = [
        {
            "name": "Default",
            "link": "mapbox://styles/mapbox/streets-v11"
        },
        {
            "name": "Dark",
            "link": "mapbox://styles/mapbox/dark-v10"
        },
        {
            "name": "Satellite",
            "link": "mapbox://styles/mapbox/satellite-v9"
        },
        {
            "name": "Outdoors",
            "link": "mapbox://styles/mapbox/outdoors-v11"
        },
        {
            "name": "Light",
            "link": "mapbox://styles/mapbox/light-v10"
        },
        {
            "name": "Navigation",
            "link": "mapbox://styles/mapbox/navigation-day-v1"
        }
    ]


    const currentPop = useRef(null)

    //this is exactly like component did mount in classes 
    // useEffect(() => {
    //     if (map.current) return; // initialize map only once
    //     map.current = new mapboxgl.Map({
    //         container: mapContainer.current,
    //         style: mapStyle,
    //         center: [lng, lat],
    //         zoom: zoom
    //     });
    // });





let wordMatches = (allObjects, word)=> {
    let matches = [];
    for(let i = 0 ; i < allObjects.length; i++){
        //console.log(i)
        if (allObjects[i] && allObjects[i].name){
            // console.log(allObjects[i].name)
            let name = allObjects[i].name;
            const re = /Nip/g;

            let str = "" + word.toLowerCase() + "";
            //console.log(str);
            let re1 = new RegExp(str, "g")
            //console.log("?>>", firstTry);
            let foundShort = re1.exec(name.toLowerCase())
            if (foundShort) {
                //console.log("found => ", name)
                matches.push(allObjects[i]);
            }

            // console.log(word.match(re))
            // if (re.test(name)) {
            //     console.log(word, name)
            //     matches.push(name);
            // }
        }

    }
    return matches;
}






    let newHtml = (feature)=> {
    let phoneNumbers = [];
    let phoneDiv = `<div class='display-flex column-gap-0-5-rem'>
                        <p class="margin-5-px">Phone </p><div class="">`
    if (feature.phone) {
        phoneNumbers = feature.phone.split("%");
        phoneNumbers.map(item => {
            phoneDiv += `${item}\n`
        })
    }
    phoneDiv += `</div></div>`;
    //less than two because by default we have "" in the database
    if (phoneNumbers.length < 1 || (phoneNumbers.length === 1 && phoneNumbers[0] === "")) {
        phoneDiv = "";
    }

    let services = [];
    let filterDiv = `<div class='display-flex column-gap-0-5-rem'>
                        <p class="margin-3-px">Services </p><div class="">`
    if (feature.filters) {
        services = feature.filters.split("%");
        services.map(item => {
            filterDiv += `<p class="background-color-light-pink width-fit border-radius-5-px padding-2-px margin-3-px">${item}</p>`
        })
    }

    filterDiv += `</div></div>`;
    if (services.length < 1 || (services.length === 1 && services[0] === "")) {
        filterDiv = "";
    }

    let violence = [];
    let remarksDiv = `<div class='display-flex column-gap-0-5-rem'>
                        <p class="margin-3-px">Type of violence </p><div class="">`

    if (feature.remarks) {
        violence = feature.remarks.split("%");
        violence.map(item => {
            remarksDiv += `<p class="background-color-light-pink width-fit border-radius-5-px padding-2-px margin-3-px">${item}</p>`
        })
    }

    remarksDiv += `</div></div>`;
    if (violence.length < 1 || (violence.length === 1 && violence[0] === "")) {
        remarksDiv = "";
    }


    const html = (`
                <div class='Pin'>
                    <div class='display-flex column-gap-0-5-rem margin-3-px'>
                        <p class='bold margin-3-px'>${feature.name}</p>
                    </div>

                    ${(feature.description !== "" && feature.description !== null) ? `<p class="margin-3-px">${feature.description}</p>` : ""}

                    <div class='display-flex column-gap-0-5-rem margin-3-px'>
                        <p class="margin-3-px bold">${(feature.street_number !== "" && feature.street_number !== null) ? " " + feature.street_number + " " : ""} ${(feature.street_name !== "" && feature.street_name !== null) ? " " + feature.street_name + "," : ""} ${(feature.suite !== "" && feature.suite !== null) ? " " + feature.suite + "," : ""} ${(feature.city !== "" && feature.city !== null) ? " " + feature.city + "," : ""} ${(feature.province !== "" && feature.province !== null) ? " " + feature.province + "," : ""} ${(feature.postal_code !== "" && feature.postal_code !== null) ? " " + feature.postal_code : ""}</p>
                    </div>

                 

              

                    

                  
                <a href="https://flourishaccess.ca/contact" target="_blank" class="Change_Button">Suggest Changes!</a>
                <a href="#hashLink"><p><span class="bold">></span> Read More</p></a>
                <div id="hashLink">
                       ${(feature.website && feature.website !== "") ?
                        `<div class='display-flex column-gap-0-5-rem margin-3-px' >
                        <p class="margin-3-px">Website </p>
                        <a href=${feature.website} target="_blank" rel="noreferrer">${feature.website}</a>
                        </div >`
                        : ""}

                        ${(feature.email && feature.email !== "") ?
                        `<div class='display-flex column-gap-0-5-rem margin-3-px' >
                        <p class="margin-3-px">Email</p>
                        <p class="margin-3-px">${feature.email}</p>
                        </div >`
                        : ""}
                    ${phoneDiv}
    
                    ${remarksDiv}
              
                    ${filterDiv}
                    <a href="#"><span class="bold">^</span> Collapse</a>
                </div>
        

                </div>`)

    return html;

}







   






















    const markerClicked = (data) => {
        const {name, email, website, description} = data
        // window.alert(`${name}\n${email}\n${website}\n${description}`);
        setSelectedPin(data);
    };

    const markerMouseIn = (data) => {
        window.alert(data.name);
    };

    useEffect(()=>{
        //console.log("yay")
        
        let cancelProcessing = false;

        if(!allPins){cancelProcessing = true;}

        let newPinsState = allPins
        if (!cancelProcessing && provinceFilter !=="Select"){
            newPinsState = allPins.filter(item => item?.province===provinceFilter).map(item => item)
        }
        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }
        else if (violenceFilter !=="Select"){
            let violeneceFiltering = [];
            for (let i = 0; i < newPinsState.length; i++){
                if (newPinsState[i].remarks){
                    let itemViolenceArray = newPinsState[i].remarks.split("%")
                    if (itemViolenceArray.length < 1) {
                        continue;
                    } else {
                        for (let k = 0; k < itemViolenceArray.length; k++) {
                            if (itemViolenceArray[k] === violenceFilter) {
                                violeneceFiltering.push(newPinsState[i])
                                break;
                            }
                        }
                    }
                }
 
            }
            newPinsState = violeneceFiltering;
        }

        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }
        else if (serviceFilter !== "Select") {
            let serviceFiltering = [];
            for (let i = 0; i < newPinsState.length; i++) {
                if (newPinsState[i].filters){
                    let itemServiceArray = newPinsState[i].filters.split("%")
                    if (itemServiceArray.length < 1) {
                        continue;
                    } else {
                        for (let k = 0; k < itemServiceArray.length; k++) {
                            if (itemServiceArray[k] === serviceFilter) {
                                serviceFiltering.push(newPinsState[i])
                                break;
                            }
                        }
                    }
                }
            }
            newPinsState = serviceFiltering
        }

   

        if (cancelProcessing || !newPinsState || newPinsState.length < 1) { cancelProcessing = true }
   

        //console.log(markers)
        if (markers !== null) {
            for (var i = markers.length - 1; i >= 0; i--) {
                markers[i].remove();
            }
        }
        let newMarkers = []
        let newFeature = []

        if(!cancelProcessing){
            newPinsState.forEach((feature) => {

                // const ref = React.createRef();
                // ref.current = document.createElement("div");
                // const rd = ReactDOM.createRoot(ref.current);
                // rd.render(
                //     <Marker onClick={markerClicked} onMouseIn={markerMouseIn} feature={feature} map={map.current} setSelectedMarker={setSelectedMarker} currentPoppup={currentPop} />
                // )

     
                let coordinates = [(feature && feature?.lng !== null) ? feature.lng : 0, (feature && feature?.lat !== null) ? feature.lat : 0]
                try {

                    if ((feature.lng && !validCoordinate("lng", feature.lng)) || (feature.lat && !validCoordinate("lat", feature.lat))) {
                        //console.log(">>>>>> invalide lng", feature)
                    }else{

                        var marker = new mapboxgl.Marker({ color:"#ac94c4"})
                        .setLngLat(coordinates)
                        .setPopup(new mapboxgl.Popup({ closeOnClick: true }).setHTML(newHtml(feature)))
                        .addTo(map.current);

                        marker.getElement().addEventListener('click', ()=>{
                            // if(currentPop.current !== null){
                            //     currentPop.current.togglePopup();
                            // }
                            // currentPop.current = marker;
                            setSelectedMarker(feature)
                            // marker.getElement().classList.add("green")
                            // marker
                            // console.log(marker)
                        })

                        // var marker = new mapboxgl.Marker(ref.current).setLngLat(coordinates).addTo(map.current)
                        newMarkers.push(marker)
                        newFeature.push(feature)
                    }
               
          
                }
                catch(err){
                    console.log(err)
                }
                

            })
        }

        // we need to calculate the average lat and lng and make the map fly there
        let totalLng = 0 ;
        let totalLat = 0 ;
        // console.log(newFeature)
        for (let i = 0; i < newFeature.length; i++){
            totalLng += parseFloat(newFeature[i].lng);
            totalLat += parseFloat(newFeature[i].lat);
        }
        // console.log(totalLat, totalLng)
        if (newFeature.length > 0){
            let averageLng = totalLng / newFeature.length;
            let averageLat = totalLat / newFeature.length;
            //console.log(averageLat, averageLng)
            const end = {
                center: [averageLng, averageLat],
                zoom: (provinceFilter==="Select" && violenceFilter === "Select" && serviceFilter === "Select")? 3 : 4.5,
                bearing: 0,
                pitch: 0
            };
            map.current.flyTo({ ...end, duration: 12000, essential: true })
        }

        setMarkers(newMarkers)
        setSelectedPins(newFeature);
        setSearchResult(newFeature)

    }, [provinceFilter, violenceFilter, serviceFilter, allPins])


    const handleClose = () => {
        setErrorMessage(null);
    }

    useEffect(() => {
        // if (!map.current) return; // wait for map to initialize
        // map.current.on('move', () => {
        //     setLng(map.current.getCenter().lng.toFixed(4));
        //     setLat(map.current.getCenter().lat.toFixed(4));
        //     setZoom(map.current.getZoom().toFixed(2));
        // });
        //console.log(lng, lat, zoom);

        if (!loaded.current) {
            loaded.current = true;


            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: mapStyle,
                center: [lng, lat],
                zoom: zoom,
            });

            map.current.on('load', function(){
                map.current.resize();
            })

            // map.current.on("load", function () {
            //     // Add an image to use as a custom marker
            //     map.current.loadImage(
            //         "https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png",
            //         function (error, image) {
            //             if (error) throw error;
            //             map.current.addImage("custom-marker", image);
            //             // Add a GeoJSON source with multiple points
            //             map.current.addSource("points", {
            //                 type: "geojson",
            //                 data: {
            //                     type: "FeatureCollection",
            //                     features: geoJson.features,
            //                 },
            //             });
            //             // Add a symbol layer
            //             map.current.addLayer({
            //                 id: "points",
            //                 type: "symbol",
            //                 source: "points",
            //                 layout: {
            //                     "icon-image": "custom-marker",
            //                     // get the title name from the source's "title" property
            //                     "text-field": ["get", "title"],
            //                     "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
            //                     "text-offset": [0, 1.25],
            //                     "text-anchor": "top",
            //                 },
            //             });
            //         }
            //     );

            // });

            mysqlServices.getPins().then(data => {
                //console.log(data);
                let pins = data.data;
                if (!pins) { return }

                setAllPins(pins);


            }).catch(err => {
                console.log(err);
            })


            map.current.on('move', () => {
                // setLng(map.current.getCenter().lng.toFixed(4));
                // setLat(map.current.getCenter().lat.toFixed(4));
                setLng(lng);
                setLat(lat);
                setZoom(map.current.getZoom().toFixed(2));
            });
            map.current.on('click', (e) => {
                // console.log(e)
                let { lat, lng } = e.lngLat;

                // console.log(lng, lat)
            })
            map.current.on('zoom', (e) => {
                // console.log(e);
                // console.log(window.location.href, e);
            })

            map.current.addControl(new mapboxgl.NavigationControl(), "bottom-left");

        }
        // else {
        //     loaded.current = false;
        // }
    }, []);


    function updateStyle(str) {
        // this.setState({...this.state.zoom, mapStyle: str});
        // console.log(this.state);
        map.current.setStyle(str);

    }

    const handleMapStyleChange = (value)=>{
        //console.log("change ", value)
        updateStyle(value)
    }

    const handleFilterChange = (filterType, value) => {
        //console.log(filterType, value)
        switch(filterType){
            case "province":
                setProvinceFilter(value);
                break;
            case "violence":
                setViolenceFilter(value);
                break;
            case "service":
                setServiceFilter(value);
                break;
            default:
                break;
        }
    }

    const handleChange = (target, value) => {
        //console.log(target, value)
        let newPin = {...adminPin};
        newPin[target] = value;
        
        setAdminPin(newPin);
        // switch (target) {
        //     case "name":
        //         setAdminPin({ ...adminPin, "name": value });
        //         break;
        //     case "street_number":
        //         setAdminPin({ ...adminPin, "street_number": value });
        //         break;
        //     default:
        //         break;

        // }
    }

    const validCoordinate = (t, val) => {
        if (t === "lng") {
            if (val <= 180.0 && val >= -180.0) {
                return true;
            } else {
                return false;
            }
        }
        else if (t === "lat") {
            // console.log(t, val, val > 100.0)
            if (val <= 90.0 && val >= -90.0) {
                return true;
            } else {
                return false;
            }
        }
    }
    const updatePin = () => {
        //console.log(adminState)
        if(!(adminState==="true")){
            return;
        }
        if (adminPin.lng && !validCoordinate("lng", adminPin.lng)) {
            //console.log("invalide lng")
            setErrorMessage("invalide lng val <= 180.0 && val >= -180.0")
            return;
        }
        if (adminPin.lat && !validCoordinate("lat", adminPin.lat)) {
            //console.log("invalide lat val <= 90.0 && val >= -90.0")
            setErrorMessage("invalide lat val <= 90.0 && val >= -90.0")
            return;
        }
        setSpinnerOn(true);
        //console.log("updating pin")
        let id = selectedMarker.pin_id;
        let objectWihoutId = {...adminPin}
        delete objectWihoutId["pin_id"]
        //console.log(objectWihoutId)
        mysqlServices.updatePin(objectWihoutId, id).then((data)=>{
            //console.log(data);
            setSpinnerOn(false);
            // setErrorMessage("Updated successfully ✔️")
        }).catch(err => {
            setSpinnerOn(false)
        })
    }
    const removePin = () => {
        if (!(adminState === "true")) {
            return;
        }
        setSpinnerOn(true);
        //console.log("removing pin")
        let id = selectedMarker.pin_id;
        mysqlServices.removePin(id).then((data) => {
            //console.log(data);
            setSpinnerOn(false);
            setErrorMessage("Remove successfully ✔️")
        }).catch(err => {
            setSpinnerOn(false);
        })
    }

    const [keyword, setKeyword] = useState("");
    const [searchResult, setSearchResult] = useState([]);

    const handleKeywordChange = (e)=>{
        const word = e.currentTarget.value;
        setKeyword(word)
    }

    const handleSearch = ()=>{
        let result = wordMatches(selectedPins, keyword)
        //console.log(result);
        setSearchResult(result)
    }

    const handleSearchResultClose = ()=> {
        setSearchResult([]);
    }

    const handleResultSelection = (item)=> {
        // const start = {
        //     center: [lng, lat],
        //     zoom: zoom,
        //     pitch: 0,
        //     bearing: 0
        // };
        const end = {
            center: [item.lng, item.lat],
            zoom: 15,
            bearing: 0,
            pitch: 0
        };
        // const target = isAtStart ? end : start;
        // isAtStart = !isAtStart;
        map.current.flyTo({...end, duration: 12000, essential: true})
    }


    return (
        <div className='relative Map_Container'>
            {/* <input readOnly id="ip" type="text" placeholder="99.238.35.3" value="99.238.35.3"/> */}
            {/* <input onChange={() => { doNothing(); }} id="ip" type="text" placeholder="99.238.35.3" value="99.238.35.3" />
            <button style={getLocationStyle} type="button" onClick={() => { doNothing(); }} className="styles mapButton">Get Location</button>
            <button className="styles mapButton" style={signOutStyle} onClick={() => { signOut(); }}>Sign out</button>
            <p style={welcomeMessegeStyle}>{`Welcome you are logged in as ${email1}`}</p> */}
            <Modal show={errorMessage !== null} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>System Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage + " !"}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            { (selectedMarker && adminState==="true") ? 
                <div className='Selected_Pin_Container'>

                    <div className='Admin-Pin'>
                        <div className='display-flex column-gap-0-5-rem'>
                            <span className='cursor-pointer icons-close-color' onClick={() => { setSelectedMarker(null) }}>
                                <AiFillCloseCircle />
                            </span>
                        </div>

                        <div className="display-flex">
                            <input type="number" placeholder="lng" value={adminPin.lng ?? ""} onChange={(e)=> {handleChange("lng", e.currentTarget.value)}} />
                            <input type="number" placeholder="lat" value={adminPin.lat ?? ""} onChange={(e) => { handleChange("lat", e.currentTarget.value) }} />
                        </div>

                        <input type="text" placeholder="name" value={adminPin.name ?? ""} onChange={(e) => { handleChange("name", e.target.value) }}  />

                        <div className="display-flex">
                            <input type="text" placeholder="street number" value={adminPin.street_number ?? ""} onChange={(e) => { handleChange("street_number", e.target.value) }} />
                            <input type="text" placeholder="street name" value={adminPin.street_name ?? ""} onChange={(e) => { handleChange("street_name", e.currentTarget.value) }} />
                            <input type="text" placeholder="suite" value={adminPin.suite ?? ""} onChange={(e) => { handleChange("suite", e.currentTarget.value) }} />
                            <input type="text" placeholder="city" value={adminPin.city ?? ""} onChange={(e) => { handleChange("city", e.currentTarget.value) }} />
                            <input type="text" placeholder="province" value={adminPin.province ?? ""} onChange={(e) => { handleChange("province", e.currentTarget.value) }} />
                            <input type="text" placeholder="postal code" value={adminPin.postal_code ?? ""} onChange={(e) => { handleChange("postal_code", e.currentTarget.value) }} />
                        </div>

                        <input type="text" placeholder="webiste" value={adminPin.website ?? ""} onChange={(e) => { handleChange("website", e.currentTarget.value) }} />
                        <input type="text" placeholder="phone" value={adminPin.phone ?? ""} onChange={(e) => { handleChange("phone", e.currentTarget.value) }} />
                        <input type="text" placeholder="email" value={adminPin.email ?? ""} onChange={(e) => { handleChange("email", e.currentTarget.value) }} />
                        <input type="text" placeholder="remarks" value={adminPin.remarks ?? ""} onChange={(e) => { handleChange("remarks", e.currentTarget.value) }} />
                        <input type="text" placeholder="filters" value={adminPin.filters ?? ""} onChange={(e) => { handleChange("filters", e.currentTarget.value) }} />
                        <input type="text" placeholder="description" value={adminPin.description ?? ""} onChange={(e) => { handleChange("description", e.currentTarget.value) }} />


                        <div className='display-flex'>
                            <button onClick={() => { updatePin() }} >Update Pin</button>
                            <button onClick={() => { removePin() }} >Remove Pin</button>
                            {spinnerOn ? <Spinner animation="border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </Spinner> : null}
                        </div>
       


                    </div>


                </div>
                : null
            }
            

            <div className="Styles_Container">
                <Form.Select onChange={(e)=> {handleMapStyleChange(e.target.value)}}>
                    {mapStyles.map(item => <option value={item.link}>{item.name}</option>)}
                </Form.Select>
                {searchResult.length > 0 ? 
                    <div className='margin-top-1-rem background-color-white'>
                        <div className='margin-top-1-rem'>
                            <span className='padding-1-rem '>{t("Filter Results")}</span>
                            <span className='float-right  hover-background-color-light-pink cursor-pointer' onClick={()=> {handleSearchResultClose()}}><GrFormClose /></span>
                        </div>
                    
                        <div className='padding-1-rem width-20-rem height-15-rem display-flex flex-direction-column  overflow-y-scroll column-gap-0-5-rem row-gap-0-5-rem'>
                            {searchResult.map(item => <span className='background-color-light-pink border-radius-5-px padding-2-px cursor-pointer' onClick={()=>{handleResultSelection(item)}}>{item.name}</span>)}
                        </div>
                    </div>
                   :
                null
                }
            </div>

            <div className='Filters_Container'>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{t("Filters")}</Accordion.Header>
                        <Accordion.Body>
                            <div className='display-flex flex-direction-column column-gap-2-rem row-gap-2-rem'>
                                <div className='display-flex flex-direction-column text-center'>
                                    <p className='margin-bottom-0-5-rem'>{t("Type of Service")}</p>
                                    <Form.Select onChange={(e) => { handleFilterChange("service", e.target.value) }}>
                                        {services.map(item => <option value={item}>{t(item)}</option>)}
                                    </Form.Select>
                                </div>
                                <div className='display-flex flex-direction-column text-center'>
                                    <p className='margin-bottom-0-5-rem'>{t("Type of Violence")}</p>
                                    <Form.Select onChange={(e) => { handleFilterChange("violence", e.target.value) }}>
                                        {violenceTypes.map(item => <option value={item}>{t(item)}</option>)}
                                    </Form.Select>
                                </div>
                                <div className='display-flex flex-direction-column text-center'>
                                    <p className='margin-bottom-0-5-rem'>{t("Province")}</p>
                                    <Form.Select onChange={(e) => { handleFilterChange("province", e.target.value) }}>
                                        {provinces.map(item => <option value={item}>{t(item)}</option>)}
                                    </Form.Select>
                                </div>
                                <div className='display-flex flex-direction-column text-center'>
                                    <div className='display-flex'>
                                        <input className='border-left-radius-5-px' type="text" placeholder={t('Ex. YMCA')} value={keyword} onChange={(e)=>{handleKeywordChange(e)}} />
                                        <button className='border-right-radius-5-px ' onClick={() => { handleSearch() }} >Search</button>
                                    </div>
                                </div>
            
                        
                       
                           

                            </div>
      
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>

            </div>

            <div className='Selected_Pin_Container'>
                {selectedPin ? 
                    <div className='Pin'>
                        <div className='display-flex column-gap-0-5-rem'>
                            <span className='cursor-pointer icons-close-color' onClick={() => { setSelectedPin(null) }}>
                                <AiFillCloseCircle />
                            </span>
                            <p>{selectedPin.name}</p>
                        </div>
           
                        <div className='display-flex column-gap-0-5-rem'>
                            <p>{"Website:"}</p> 
                            <a href={selectedPin.website} target="_blank" rel="noreferrer">{selectedPin.website}</a>
                        </div>
                    </div>
  
           
                    : null
                }


            </div>

            <div ref={mapContainer} className="Map" />
            {/* <div className="Sidebar">
                Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
            </div> */}
        </div>
    );
}

export default MapFunction;