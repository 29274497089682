import React from "react";
import { useTranslation } from "react-i18next";

import advocasyImg2 from "../../assets/images/Re-design assets/Advocacy timeline.png";

import WhiwhContact from "../snippets.components/whiwhContant.component";

import topImg from "../../assets/images/Re-design assets/Copy of About us Banner.png"
import "./about.css";

function About(params) {
    const { t, i18n } = useTranslation();

    return (
        <div className='About-Container'>
            <img src={topImg} alt="thirve" className="Home-Block-Image-0 image-motion-2 image-m-2" />
            <div className='background-flourish'>
                <div className='Home-Block-2'>

                    <div className='font-size-16-px display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem'>
                        <div className='display-flex flex-direction-row  align-items-center row-gap-0-5-rem column-gap-0-5-rem flex-wrap '>
                            <span className='width-60-percent text-decoration-none flex-grow-1'>
                                <span className='f bold'>{"Women’s Health in Women’s Hands"}</span>
                                {" " + t('about-page.body.block1.text1')}
                            </span>
                            <span className='width-60-percent text-decoration-none flex-grow-1'>
                                {" " + t('about-page.body.block1.text2')}
                            </span>
                        </div>
                    </div>
                    <div className='display-flex flex-direction-column row-gap-1-rem column-gap-1-rem'>
                        <span className='font-size-22-px bold'>{t('about-page.body.block2.header')}</span>
                        <div className='display-flex flex-direction-row  align-items-center row-gap-1-rem column-gap-1-rem flex-wrap'>
                            <div className='display-flex flex-direction-column row-gap-1-rem column-gap-1-rem font-size-16-px width-60-percent flex-grow-1'>
                                <div className="">
                                    <span className='font-size-20-px bold'>{"Flourish "}</span>
                                    {t('about-page.body.block2.text1')}
                                </div>

                                <div>
                                    <img src={advocasyImg2} alt="thirve" className='width-100-percent ' />
                                </div>


                                <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem">
                                    <span className='font-size-22-px bold'>{t('about-page.body.block3.header')}</span>
                                    <span className=''>{t('about-page.body.block3.text1') + " "}<a href="https://www.unhcr.org/gender-based-violence.html" target="_blank" rel="noreferrer">{t('about-page.body.block3.text2')}</a>{t('about-page.body.block3.text3') + " "}</span>
                                </div>
                                <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem">
                                    <span className='font-size-22-px bold'>{t('about-page.body.block4.header')}</span>
                                    <span className=''>{t('about-page.body.block4.text1') + " "}<a href="https://www.canada.ca/en/women-gender-equality/news/2021/08/tbc---department-for-women-and-gender-equality-canada---gender-based-violence-program.html" target="_blank" rel="noreferrer">{t('about-page.body.block4.text2')}</a>{t('about-page.body.block4.text3') + " "}</span>
                                    <ol>
                                        <li>
                                            {t('about-page.body.block4.text4.1')}
                                        </li>
                                        <li>
                                            {t('about-page.body.block4.text4.2')}
                                        </li>
                                        <li>
                                            {t('about-page.body.block4.text4.3')}
                                        </li>
                                    </ol>
                                </div>



                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <WhiwhContact />


        </div>
    )
}
export default About;