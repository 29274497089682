import React, {useState} from "react";
import "./selection.css"
import { RiArrowDownSFill } from "react-icons/ri"
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

function Selection({label,values,onChange,open,handleOpen,currentValue,setCurrentValue, itemStyle, dropdownStyle, labelStyle, containerStyle}){
    const { t, i18n } = useTranslation();
    const handleValueChange = (value) => {
        setCurrentValue(value);
    };
    const handleChange = (value) => {
        //handleValueChange(value);
        // call method, if it exists
        if (onChange) onChange(value);
        // close, after all tasks are finished
        handleOpen(false);
    };

    return (
        <div className={`Selection-Container ${containerStyle}`} >
            <div className={`Selection-Label-Button ${labelStyle} color-pink`} >
                <span className="color-pink" >{currentValue !== "" ? t(currentValue) : label}</span>
                <div className={`Selection-Arrow`}>
                    <RiArrowDownSFill />
                </div>
            </div>
            <div className={`Dropdown-Style ${open ? "display-block" : "invisible"} ${dropdownStyle}`}>
                {values.map(({name, link}, index)=>(
                    <Link to={link} key={link + index} className="text-decoration-none">
                        <div key={name+link+index} className={`Dropdown-Item ${itemStyle}`} onClick={()=> {handleChange(name)}}>
                            {t(name)}
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
}
export default Selection;