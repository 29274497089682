import React, { useState, useEffect, useRef } from 'react';

import "./gbvRisk.css";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import mysqlServices from '../../../services/mysqlServices';
// import { Link } from 'react-router-dom';
import ReferralForm from "../../../assets/pdf/GBV Case Management Referral Form.pdf";
import Handbook from "../../../assets/pdf/GBV Resource Handbook 2024.pdf";

export default function GbvRiskAssessment() {
    const { t, i18n } = useTranslation();

    const options = [
        { "value": 0, "label": { "en": "Never", "fr": "Jamais" } },
        { "value": 1, "label": { "en": "One time", "fr": "Une fois" } },
        { "value": 2, "label": { "en": "Once a month", "fr": "Une fois par mois" } },
        { "value": 3, "label": { "en": "Every week", "fr": "Toutes les semaines" } },
        { "value": 4, "label": { "en": "Every day", "fr": "Tous les jours" } },
    ]

    const getScoreResult = (score) => {
        if (typeof score !== "number") {
            return {
                "err": "true",
                "en": [
                    "Something went wrong in processing your score."
                ],
                "fr": [
                    "Une erreur s'est produite lors du traitement de votre score."
                ]
            }
        }
        if (score < 6) {
            return {
                "en": [
                    "Your responses suggest that you have a low risk of experiencing GBV.",
                    "If you feel like you need to talk to someone about your situation, it might be helpful to reach out to someone you trust. They can provide a listening ear and support. Remember, if you feel like something is not right or you might be in danger, you don't have to go through this alone."
                ],
                "fr": [
                    "Vos réponses suggèrent que vous avez un faible risque d’être victime de VBG.",
                    "Si vous ressentez le besoin de parler de votre situation à quelqu’un, il peut être utile de contacter une personne de confiance. Ils peuvent fournir une oreille attentive et un soutien. N'oubliez pas que si vous sentez que quelque chose ne va pas ou que vous pourriez être en danger, vous n'êtes pas obligé de vivre cela seul."
                ]
            }
        } else if (score > 5 && score < 15) {
            return {
                "en": [
                    "Your responses suggest that you may either have a medium risk of experiencing GBV or that you are presently experiencing GBV.",
                    "It is recommended that you seek/access immediate support to ensure your safety. The first step you can take is to talk to someone you trust. Remember, if you are in danger, you don't have to go through this alone."
                ],
                "fr": [
                    "Vos réponses suggèrent que vous présentez soit un risque moyen d’être victime de VBG, soit que vous êtes actuellement victime de VBG.",
                    "Il est recommandé de rechercher/accéder à une assistance immédiate pour assurer votre sécurité. La première chose à faire est d’en parler à une personne de confiance. N'oubliez pas que si vous êtes en danger, vous n'êtes pas obligé de traverser cette épreuve seul."
                ]
            }
        } else if (score > 14 && score < 29) {
            return {
                "en": [
                    "Your responses suggest that you may either have a high risk of experiencing GBV or that you are presently experiencing GBV.",
                    "It is recommended that you seek/access immediate support to ensure your safety. The first step you can take is to talk to someone you trust. Remember, if you are in danger, you don't have to go through this alone."
                ],
                "fr": [
                    "Vos réponses suggèrent que vous présentez un risque élevé de violence sexiste ou que vous êtes actuellement victime de VBG.",
                    "Il est recommandé de rechercher/accéder à une assistance immédiate pour assurer votre sécurité. La première chose à faire est d’en parler à une personne de confiance. N'oubliez pas que si vous êtes en danger, vous n'êtes pas obligé de traverser cette épreuve seul."
                ]
            }
        } else {
            return {
                "err": "true",
                "en": [
                    "The score is invalid."
                ],
                "fr": [
                    "Le score est invalide."
                ]
            }
        }
    }


    const [errorTrigger, setErrorTrigger] = useState(false);
    const [scoreResult, setScoreResult] = useState(null);

    const captchaRef = useRef(null)
    const [loadedCaptcha, setLoadedCaptcha] = useState(false);

    useEffect(() => {
        // console.log(user);
        // window.initAutocomplete = initAutocomplete;
        // initAutocomplete();
        if (!loadedCaptcha) {
            setTimeout(() => {
                setLoadedCaptcha(true);
            }, 1500);
        }

    })


    const [questionsAndAnswers, setQuestionsAndAnswers] = useState([
        {
            "index": 1,
            "question": {
                "en": "Have you felt uncomfortable doing or saying things around your current partner, parent, child, family member or a particular person in your life?",
                "fr": "Vous êtes-vous senti mal à l'aise de faire ou de dire des choses avec votre partenaire actuel, vos parents, vos enfants, un membre de votre famille ou une personne en particulier dans votre vie?"
            },
            "selected": null,
        },
        {
            "index": 2,
            "question": {
                "en": "How often did you experience verbal abuse (i.e. insult/yell/shout/curse at you or talk down to you) by your partner, ex-partner, parent, child, family member or a particular person in your life?",
                "fr": "À quelle fréquence avez-vous été victime de violence verbale (c'est-à-dire insulter/crier/crier/insulter ou vous insulter) de la part de votre partenaire, ex-partenaire, parent, enfant, membre de votre famille ou d'une personne en particulier dans votre vie?"
            },
            "selected": null,
            "sub_question": {
                "en": "If yes, have they ever done it in the presence of others, in public or during a phone/video call?",
                "fr": "Si oui, l'ont-ils déjà fait en présence d'autres personnes, en public ou lors d'un appel téléphonique/vidéo?"
            },
            "sub_selected": null
        },
        {
            "index": 3,
            "question": {
                "en": "How often did you experience physical abuse (i.e. punching, kicking, pressured/unwanted sexual acts) by your partner, an ex-partner, parent, child, family member or a particular person in your life?",
                "fr": "À quelle fréquence avez-vous été victime de violence physique (c'est-à-dire coups de poing, coups de pied, actes sexuels sous pression/non désirés) de la part de votre partenaire, d'un ex-partenaire, d'un parent, d'un enfant, d'un membre de votre famille ou d'une personne en particulier dans votre vie?"
            },
            "selected": null,
            "sub_question": {
                "en": "If yes, have they ever done it in the presence of others or in public?",
                "fr": "Si oui, l’ont-ils déjà fait en présence d’autres personnes ou en public?"
            },
            "sub_selected": null
        },
        {
            "index": 4,
            "question": {
                "en": "How often did your partner, an ex-partner, parent, child, family member or a particular person in your life threaten to harm you/someone you care about or make you feel afraid/scared?",
                "fr": "À quelle fréquence votre partenaire, un ex-partenaire, un parent, un enfant, un membre de votre famille ou une personne en particulier dans votre vie a-t-il menacé de vous faire du mal ou de faire du mal à quelqu'un qui vous est cher ou de vous faire peur?"
            },
            "selected": null,
        },
        {
            "index": 5,
            "question": {
                "en": "How often did your partner, an ex-partner, parent, child, family member or a particular person in your life control who you see, where you go, what you do, or what you wear?",
                "fr": "À quelle fréquence votre partenaire, un ex-partenaire, un parent, un enfant, un membre de votre famille ou une personne en particulier dans votre vie contrôlait-il qui vous voyez, où vous allez, ce que vous faites ou ce que vous portez?"
            },
            "selected": null,
        }
    ])

    const handleOptionSelect = (value, questionIndex, sub) => {
        var qaCopy = [...questionsAndAnswers];
        if (sub) {
            delete qaCopy[questionIndex - 1]["sub_err"]
            qaCopy[questionIndex - 1]["sub_selected"] = value
        } else {
            delete qaCopy[questionIndex - 1]["err"]
            qaCopy[questionIndex - 1]["selected"] = value
        }
        setQuestionsAndAnswers(qaCopy)
    }

    const getResult = () => {
        var qaCopy = [...questionsAndAnswers];
        var allSelected = true;
        for (let itm = 0; itm < qaCopy.length; itm++) {
            if (qaCopy[itm].selected === null) {
                qaCopy[itm]["err"] = "true";
                allSelected = false;
            }
            if (qaCopy[itm].sub_selected === null) {
                qaCopy[itm]["sub_err"] = "true";
                allSelected = false;
            }
        }
        if (allSelected) {
            const token = captchaRef.current.getValue();
            captchaRef.current.reset();
            if (!token) {
                setErrorTrigger(true);
            } else {
                setErrorTrigger(false);
                if (Object.keys(questionsAndAnswers) === 0) {
                    return;
                } else {
                    mysqlServices.addSurveyAnswers({ "surveyAnswers": questionsAndAnswers, "captchaTokenV2": token }).then(data => {
                        console.log(data)
                        let score = data?.data?.score;
                        handleShowResult(score);
                    }).catch(err => {
                        console.log(err)
                    })
                }

            }
        } else {
            setErrorTrigger(true);
        }
    }



    const handleShowResult = (score) => {
        var scoreResult = getScoreResult(score);
        var scoreResultLangOption = scoreResult;
        if (!scoreResult.err) {
            if (i18n.language === "fr") {
                scoreResultLangOption = scoreResult.fr;
                scoreResultLangOption.push("Si vous souhaitez parler de votre expérience avec un professionnel, veuillez remplir le formulaire d'auto-référence pour accéder à notre gestion de cas de VBG.");
                scoreResultLangOption.push("Explorez ce site Web pour en savoir plus sur la VBG et accéder aux ressources et au soutien pour assurer votre sécurité.");
                scoreResultLangOption.push("Si vous souhaitez parler de votre expérience avec un professionnel, veuillez remplir le formulaire d'auto-référence pour accéder à notre gestion de cas de VBG.");
            } else {
                scoreResultLangOption = scoreResult.en;
                scoreResultLangOption.push("If you would like to speak with a professional about your experience, please complete the self-referral form to access our GBV case management.");
                scoreResultLangOption.push("Explore this website to learn more about GBV and access resources and support to ensure your safety.");

            }
        } else {
            if (i18n.language === "fr") {
                scoreResultLangOption = scoreResult.fr;
            } else {
                scoreResultLangOption = scoreResult.en;
            }
        }
        setScoreResult(scoreResultLangOption);
    }

    const screeningTool = () => {
        return <div className="GBV-Container">
            <div className='display-flex justify-content-center align-items-center flex-direction-column column-gap-2-rem row-gap-2-rem'>
                <div className='width-100-percent'>
                    <span className="font-size-30-px bold text-align-left">{t(`GBV Risk Assessment`)}</span>
                </div>
                <span>{t("This tool will allow you to assess if you are at risk or are experiencing violence in any type of relationship.")}</span>

                <span>{t("Please select the answer that comes closest to your experiencing over the last 12 months and not just from today.")}</span>

                <span className='bold'>{t("*All fields are required.")}</span>
            </div>

            <div className='display-flex justify-content-center align-items-left flex-direction-column column-gap-2-rem row-gap-2-rem'>
                <h3>{t("Over the last 12 months")}</h3>
                <div className='Questions-Container'>
                    {questionsAndAnswers.map(item =>
                        <div key={JSON.stringify(item)} className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem'>
                            <div className='display-flex column-gap-0-5-rem row-gap-0-5-rem'>
                                <span className='bold'>{item.index}.</span>
                                <span className=''>{i18n.language === "fr" ? item.question.fr : item.question.en}</span>
                            </div>
                            <div className={` ${errorTrigger && item.err ? "GBV-Background-Err" : ""} display-flex flex-wrap justify-content-center align-items-center column-gap-2-rem row-gap-2-rem`}>
                                {options.map(opt =>
                                    <span key={JSON.stringify(opt)} onClick={() => { handleOptionSelect(opt.value, item.index); }} className={`GBV-Option-Button ${(item?.selected === opt.value) ? "background-color-dark-pink color-white" : "background-color-dark-orange"}`}>
                                        {i18n.language === "fr" ? opt.label.fr : opt.label.en}
                                    </span>
                                )}
                            </div>
                            {item.sub_question ?
                                <div className='margin-left-3-rem display-flex flex-direction-column column-gap-1-rem row-gap-1-rem'>
                                    <div className='display-flex column-gap-0-5-rem row-gap-0-5-rem'>
                                        <span className='bold'>a.</span>
                                        <span className=''>{i18n.language === "fr" ? item.sub_question.fr : item.sub_question.en}</span>
                                    </div>
                                    <div className={`${errorTrigger && item.sub_err ? "GBV-Background-Err" : ""} display-flex flex-wrap justify-content-center align-items-center column-gap-2-rem row-gap-2-rem`}>
                                        {options.map(opt =>
                                            <span key={JSON.stringify(opt)} onClick={() => { handleOptionSelect(opt.value, item.index, true); }} className={`GBV-Option-Button ${(item?.sub_selected === opt.value) ? "background-color-dark-pink color-white" : "background-color-dark-orange"}`}>
                                                {i18n.language === "fr" ? opt.label.fr : opt.label.en}
                                            </span>
                                        )}
                                    </div>

                                </div>
                                : null}
                        </div>
                    )}
                </div>

                <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem justify-content-center with-100-percent margin-top-3-rem align-items-center'>
                    {errorTrigger ?
                        <span className='GBV-Background-Err'>Please answer all questions</span>
                        : null}
                    {loadedCaptcha &&
                        <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            theme="light"
                            sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                            ref={captchaRef}
                            onChange={null}
                            asyncScriptOnLoad={null}
                        />
                    }
                    <span onClick={() => getResult()} className='GBV-Submit-Button'>{t("Get Result")}</span>
                </div>
                {scoreResult ?
                    <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem justify-content-center with-100-percent margin-top-3-rem align-items-center'>
                        {scoreResult.err ?
                            <div>
                                {scoreResult.map(item =>
                                    <span key={item} className='bold'>{item}</span>
                                )}
                            </div>
                            : <>
                                <div className='GBV-BOX'>
                                    <div className='display-flex flex-direction-column flex-wrap jutify-content-start column-gap-1-rem row-gap-1-rem'>
                                        <h1>{t("Results: ")}</h1>
                                        <span>{scoreResult[0]}</span>
                                        <span>{scoreResult[1]}</span>
                                    </div>
                                    <div className='display-flex flex-direction-column flex-wrap jutify-content-start column-gap-1-rem row-gap-1-rem'>
                                        <h3>{t("Next Steps -")}</h3>
                                        <span>{scoreResult[2]}</span>
                                        <span>{scoreResult[3]}</span>
                                    </div>
                                    <div className='display-flex flex-wrap justify-content-space-around align-items-center column-gap-1-rem row-gap-1-rem width-100-psnt'>
                                        <span className='GBV-BOX-Button'>
                                            <a target='_blank' rel='noreferrer' href={ReferralForm} className="text-decoration-none color-pink">{t("Referral Form")}</a>
                                        </span>
                                        <span className='GBV-BOX-Button'>
                                            <a target='_blank' rel='noreferrer' href={"https://flourishaccess.ca/map"} className="text-decoration-none color-pink">{t("Access Map")}</a>
                                        </span>
                                        <span className='GBV-BOX-Button'>
                                            <a target='_blank' rel='noreferrer' href={Handbook} className="text-decoration-none color-pink">{t("Recource Booklet")}</a>
                                        </span>
                                    </div>
                                </div>
                                <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem justify-content-center'>
                                    <span className='font-size-13-px'>{t("Adapted from the WITHWomen – IPV Risk Assessment Tool developed by Dr. Patricia O’Campo from MAP Centre of Urban Health Solutions at St. Michael's Hospital.")}</span>
                                    <div>
                                        <span className='font-size-13-px'>{t("Click the links below to explore the WITH suite of apps that can help you.")}</span>
                                        <ul className='font-size-13-px'>
                                            <li><span className='bold'>{t("Screening app")}</span> <span>{t("to screen for relationship safety concerns:")}</span> <a target='_blank' rel="noreferrer" href="https://withwomen.ca">https://withwomen.ca</a></li>
                                            <li><span className='bold'>{t("Pathways")}</span> <span>{t("to plan for safety when in an unsafe relationship:")}</span> <a target='_blank' rel="noreferrer" href="https://withwomenpathways.ca">https://withwomenpathways.ca</a></li>
                                            <li><span className='bold'>{t("Promise")}</span> <span>{t("to plan for safety when in an unsafe relationship during public health emergencies:")}</span> <a target='_blank' rel="noreferrer" href="https://withwomenpromise.com">https://withwomenpromise.com</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </>

                        }
                    </div>
                    : null}

            </div>

        </div >
    }


    const mainPage = () => {
        return <div className="GBV-Container">
            <div>

                <div className='width-100-percent'>
                    <span className="font-size-30-px bold text-align-left">{t(`GBV Risk Assessment`)}</span>
                </div>
                <div><span>{t("Gender-based violence (GBV) refers to any physical, psychological, or otherwise harmful act directed at an individual based on gender. GBV can include physical, verbal, psychological, sexual, financial/economic, digital and spiritual violence inflicted in public and private spaces ")}</span><a target="_blank" rel='noreferrer' href="https://flourishaccess.ca/resources/gbv" className='bold'>{`(${t("learn-more")})`}</a></div>
            </div>

            <div className='display-flex flex-wrap align-items-center justify-content-center column-gap-3-rem row-gap-3-rem'>
                <div className='display-flex flex-direction-column align-items-center justify-content-center column-gap-2-rem row-gap-2-rem'>
                    <div className='max-width-30-rem display-flex flex-direction-column align-items-left justify-content-center column-gap-1-rem row-gap-1-rem'>
                        <span><span className='bold'>{t("Step")}{" 1: "}</span><span>{t("Answer seven quick questions to receive a safety result.")}</span></span>
                        <span><span className='bold'>{t("Step")}{" 2: "}</span><span>{t("Recognize violence risk level and receive recommendations.")}</span></span>
                        <span><span className='bold'>{t("Step")}{" 3: "}</span><span>{t("Access resources and/or self-refer for GBV case management.")}</span></span>
                    </div>
                    <div className='display-flex flex-direction-column align-items-center justify-content-center column-gap-1-rem row-gap-1-rem'>
                        <span onClick={() => { setCurrentPage("screen-tool") }} className='GBV-Top-Button'>{t("Start Screening")}</span>
                        <div className='display-flex flex-wrap align-items-center justify-content-center column-gap-0-5-rem row-gap-0-5-rem'>
                            <span className='GBV-Bottom-Button'>
                                <a target='_blank' rel='noreferrer' href={Handbook} className="text-decoration-none color-white">{t("Resource Booklet")}</a>
                            </span>
                            <span className='GBV-Bottom-Button'>
                                <a target='_blank' rel='noreferrer' href={ReferralForm} className="text-decoration-none color-white">{t("Referral Form")}</a>
                            </span>

                        </div>
                    </div>
                </div>
                <div className='display-flex flex-direction-column align-items-center justify-content-center column-gap-2-rem row-gap-2-rem'>
                    <span className='bold font-size-18-px'>{t("How does it work?")}</span>
                    <span className='max-width-30-rem'>{t("After you complete the risk screening tool, you will receive information and resources to help you stay safe and free from violence.")}</span>
                    <span className='font-size-13-px max-width-30-rem'><span className='bold'>{t("Please note: ")}</span><span className='font-size-13-px'>{t("This online screening tool is meant to provide a quick snapshot of your risk of experiencing GBV.")}</span></span>
                    <div className='max-width-30-rem'>
                        <span className='font-size-13-px'>{t("This website is not a crisis support line. If you need immediate help or are in imminent danger, you can reach out to:")}</span>
                        <ul className='margin-top-1-rem'>
                            <li className='font-size-13-px'><span className='bold'>{t("Assaulted Women's 24/7 Helpline: ")}</span><span>{t("1-866-863-0511 (Multilingual service available)")}</span></li>
                            <li className='font-size-13-px'><span className='bold'>{t("Victim 24/7 Support Line:  ")}</span><span>1-888-579-2888</span></li>
                            <li className='font-size-13-px'><span className='bold'>{t("Fem'aide 24/7 Support Line:  ")}</span><span>{t("1-877-336-2433 (French only)")}</span></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className='display-flex flex-direction-column aling-items-center justify-content-center column-gap-2-rem row-gap-2-rem'>
                <span className='bold font-size-30-px'>{t("About GBV Prevention & Care Cascade")}</span>
                <span className=''>{t("WHIWH’s prevention and care cascade program that will address the different risks and socio-cultural factors experienced by racialized women (from African, Carribean, Black, Latin American and South Asian communities) and the need for culturally appropriate and safe GBV services.")}</span>
                <span className=''>{t("The program is designed to ensure that racialized women receive care for their enduring health needs beyond immediate safety concerns. WHIWH's primary focus is violence prevention, and the program aims to link women at risk or experiencing GBV to care services.")}</span>
            </div>
        </div>
    }

    const [currentPage, setCurrentPage] = useState("main-page");
    const renderPage = {
        "main-page": mainPage(),
        "screen-tool": screeningTool()
    }

    return (
        <div className="GBV_Parent_Container">
            {/* <img src={topImg} alt="thirve" className="Home-Block-Image-0 image-motion-2 image-m-2" /> */}
            <div className="img-wrap">
                {/* <img src={backgroundImg} alt="background flourish access website" className="flourish-background-img" /> */}
                {renderPage[currentPage]}
            </div>
        </div>
    );
}



/////////////////////////////////////////
// import React, { useState, useEffect, useRef } from 'react';

// import "./gbvRisk.css";
// import { useTranslation } from "react-i18next";
// import ReCAPTCHA from "react-google-recaptcha";
// import topImg from "../../../assets/images/GBV/Banner - GBV Risk Assessment.png";
// import mysqlServices from '../../../services/mysqlServices';
// // import { Link } from 'react-router-dom';
// import ReferralForm from "../../../assets/pdf/GBV Case Management Referral Form.pdf";
// import Handbook from "../../../assets/pdf/GBV Resource Handbook 2024.pdf";

// export default function GbvRiskAssessment() {
//     const { t, i18n } = useTranslation();

//     const options = [
//         { "value": 0, "label": { "en": "Never", "fr": "Jamais" } },
//         { "value": 1, "label": { "en": "One time", "fr": "Une fois" } },
//         { "value": 2, "label": { "en": "Once a month", "fr": "Une fois par mois" } },
//         { "value": 3, "label": { "en": "Every week", "fr": "Toutes les semaines" } },
//         { "value": 4, "label": { "en": "Every day", "fr": "Tous les jours" } },
//     ]

//     const getScoreResult = (score) => {
//         if (!score || typeof score !== "number") {
//             return {
//                 "err": "true",
//                 "en": [
//                     "Something went wrong in processing your score."
//                 ],
//                 "fr": [
//                     "Une erreur s'est produite lors du traitement de votre score."
//                 ]
//             }
//         }
//         if (score > 0 && score < 6) {
//             return {
//                 "en": [
//                     "Your responses suggest that you have a low risk of experiencing GBV.",
//                     "If you feel like you need to talk to someone about your situation, it might be helpful to reach out to someone you trust. They can provide a listening ear and support. Remember, if you feel like something is not right or you might be in danger, you don't have to go through this alone."
//                 ],
//                 "fr": [
//                     "Vos réponses suggèrent que vous avez un faible risque d’être victime de VBG.",
//                     "Si vous ressentez le besoin de parler de votre situation à quelqu’un, il peut être utile de contacter une personne de confiance. Ils peuvent fournir une oreille attentive et un soutien. N'oubliez pas que si vous sentez que quelque chose ne va pas ou que vous pourriez être en danger, vous n'êtes pas obligé de vivre cela seul."
//                 ]
//             }
//         } else if (score > 5 && score < 15) {
//             return {
//                 "en": [
//                     "Your responses suggest that you may either have a medium risk of experiencing GBV or that you are presently experiencing GBV.",
//                     "It is recommended that you seek/access immediate support to ensure your safety. The first step you can take is to talk to someone you trust. Remember, if you are in danger, you don't have to go through this alone."
//                 ],
//                 "fr": [
//                     "Vos réponses suggèrent que vous présentez soit un risque moyen d’être victime de VBG, soit que vous êtes actuellement victime de VBG.",
//                     "Il est recommandé de rechercher/accéder à une assistance immédiate pour assurer votre sécurité. La première chose à faire est d’en parler à une personne de confiance. N'oubliez pas que si vous êtes en danger, vous n'êtes pas obligé de traverser cette épreuve seul."
//                 ]
//             }
//         } else if (score > 14 && score < 29) {
//             return {
//                 "en": [
//                     "Your responses suggest that you may either have a high risk of experiencing GBV or that you are presently experiencing GBV.",
//                     "It is recommended that you seek/access immediate support to ensure your safety. The first step you can take is to talk to someone you trust. Remember, if you are in danger, you don't have to go through this alone."
//                 ],
//                 "fr": [
//                     "Vos réponses suggèrent que vous présentez un risque élevé de violence sexiste ou que vous êtes actuellement victime de VBG.",
//                     "Il est recommandé de rechercher/accéder à une assistance immédiate pour assurer votre sécurité. La première chose à faire est d’en parler à une personne de confiance. N'oubliez pas que si vous êtes en danger, vous n'êtes pas obligé de traverser cette épreuve seul."
//                 ]
//             }
//         } else {
//             return {
//                 "err": "true",
//                 "en": [
//                     "The score is invalid."
//                 ],
//                 "fr": [
//                     "Le score est invalide."
//                 ]
//             }
//         }
//     }


//     const [errorTrigger, setErrorTrigger] = useState(false);
//     const [scoreResult, setScoreResult] = useState(null);

//     const captchaRef = useRef(null)
//     const [loadedCaptcha, setLoadedCaptcha] = useState(false);

//     useEffect(() => {
//         // console.log(user);
//         // window.initAutocomplete = initAutocomplete;
//         // initAutocomplete();
//         if (!loadedCaptcha) {
//             setTimeout(() => {
//                 setLoadedCaptcha(true);
//             }, 1500);
//         }

//     })


//     const [questionsAndAnswers, setQuestionsAndAnswers] = useState([
//         {
//             "index": 1,
//             "question": {
//                 "en": "Have you felt uncomfortable doing or saying things around your current partner, parent, child, family member or a particular person in your life?",
//                 "fr": "Vous êtes-vous senti mal à l'aise de faire ou de dire des choses avec votre partenaire actuel, vos parents, vos enfants, un membre de votre famille ou une personne en particulier dans votre vie?"
//             },
//             "selected": null,
//         },
//         {
//             "index": 2,
//             "question": {
//                 "en": "How often did you experience verbal abuse (i.e. insult/yell/shout/curse at you or talk down to you) by your partner, ex-partner, parent, child, family member or a particular person in your life?",
//                 "fr": "À quelle fréquence avez-vous été victime de violence verbale (c'est-à-dire insulter/crier/crier/insulter ou vous insulter) de la part de votre partenaire, ex-partenaire, parent, enfant, membre de votre famille ou d'une personne en particulier dans votre vie?"
//             },
//             "selected": null,
//             "sub_question": {
//                 "en": "If yes, have they ever done it in the presence of others, in public or during a phone/video call?",
//                 "fr": "Si oui, l'ont-ils déjà fait en présence d'autres personnes, en public ou lors d'un appel téléphonique/vidéo?"
//             },
//             "sub_selected": null
//         },
//         {
//             "index": 3,
//             "question": {
//                 "en": "How often did you experience physical abuse (i.e. punching, kicking, pressured/unwanted sexual acts) by your partner, an ex-partner, parent, child, family member or a particular person in your life?",
//                 "fr": "À quelle fréquence avez-vous été victime de violence physique (c'est-à-dire coups de poing, coups de pied, actes sexuels sous pression/non désirés) de la part de votre partenaire, d'un ex-partenaire, d'un parent, d'un enfant, d'un membre de votre famille ou d'une personne en particulier dans votre vie?"
//             },
//             "selected": null,
//             "sub_question": {
//                 "en": "If yes, have they ever done it in the presence of others or in public?",
//                 "fr": "Si oui, l’ont-ils déjà fait en présence d’autres personnes ou en public?"
//             },
//             "sub_selected": null
//         },
//         {
//             "index": 4,
//             "question": {
//                 "en": "How often did your partner, an ex-partner, parent, child, family member or a particular person in your life threaten to harm you/someone you care about or make you feel afraid/scared?",
//                 "fr": "À quelle fréquence votre partenaire, un ex-partenaire, un parent, un enfant, un membre de votre famille ou une personne en particulier dans votre vie a-t-il menacé de vous faire du mal ou de faire du mal à quelqu'un qui vous est cher ou de vous faire peur?"
//             },
//             "selected": null,
//         },
//         {
//             "index": 5,
//             "question": {
//                 "en": "How often did your partner, an ex-partner, parent, child, family member or a particular person in your life control who you see, where you go, what you do, or what you wear?",
//                 "fr": "À quelle fréquence votre partenaire, un ex-partenaire, un parent, un enfant, un membre de votre famille ou une personne en particulier dans votre vie contrôlait-il qui vous voyez, où vous allez, ce que vous faites ou ce que vous portez?"
//             },
//             "selected": null,
//         }
//     ])

//     const handleOptionSelect = (value, questionIndex, sub) => {
//         var qaCopy = [...questionsAndAnswers];
//         if (sub) {
//             delete qaCopy[questionIndex - 1]["sub_err"]
//             qaCopy[questionIndex - 1]["sub_selected"] = value
//         } else {
//             delete qaCopy[questionIndex - 1]["err"]
//             qaCopy[questionIndex - 1]["selected"] = value
//         }
//         setQuestionsAndAnswers(qaCopy)
//     }

//     const getResult = () => {
//         var qaCopy = [...questionsAndAnswers];
//         var allSelected = true;
//         for (let itm = 0; itm < qaCopy.length; itm++) {
//             if (qaCopy[itm].selected === null) {
//                 qaCopy[itm]["err"] = "true";
//                 allSelected = false;
//             }
//             if (qaCopy[itm].sub_selected === null) {
//                 qaCopy[itm]["sub_err"] = "true";
//                 allSelected = false;
//             }
//         }
//         if (allSelected) {
//             const token = captchaRef.current.getValue();
//             captchaRef.current.reset();
//             if (!token) {
//                 setErrorTrigger(true);
//             } else {
//                 setErrorTrigger(false);
//                 if (Object.keys(questionsAndAnswers) === 0) {
//                     return;
//                 } else {
//                     mysqlServices.addSurveyAnswers({ "surveyAnswers": questionsAndAnswers, "captchaTokenV2": token }).then(data => {
//                         console.log(data)
//                         let score = data?.data?.score;
//                         handleShowResult(score);
//                     }).catch(err => {
//                         console.log(err)
//                     })
//                 }

//             }
//         } else {
//             setErrorTrigger(true);
//         }
//     }



//     const handleShowResult = (score) => {
//         var scoreResult = getScoreResult(score);
//         var scoreResultLangOption = scoreResult;
//         if (!scoreResult.err) {
//             if (i18n.language === "fr") {
//                 scoreResultLangOption = scoreResult.fr;
//                 scoreResultLangOption.push("Si vous souhaitez parler de votre expérience avec un professionnel, veuillez remplir le formulaire d'auto-référence pour accéder à notre gestion de cas de VBG.");
//                 scoreResultLangOption.push("Explorez ce site Web pour en savoir plus sur la VBG et accéder aux ressources et au soutien pour assurer votre sécurité.");
//                 scoreResultLangOption.push("Si vous souhaitez parler de votre expérience avec un professionnel, veuillez remplir le formulaire d'auto-référence pour accéder à notre gestion de cas de VBG.");
//             } else {
//                 scoreResultLangOption = scoreResult.en;
//                 scoreResultLangOption.push("If you would like to speak with a professional about your experience, please complete the self-referral form to access our GBV case management.");
//                 scoreResultLangOption.push("Explore this website to learn more about GBV and access resources and support to ensure your safety.");

//             }
//         } else {
//             if (i18n.language === "fr") {
//                 scoreResultLangOption = scoreResult.fr;
//             } else {
//                 scoreResultLangOption = scoreResult.en;
//             }
//         }
//         setScoreResult(scoreResultLangOption);
//     }

//     const screeningTool = () => {
//         return <div className="GBV-Container">
//             <div className='display-flex justify-content-center align-items-center flex-direction-column column-gap-2-rem row-gap-2-rem'>
//                 <span>{t("This tool will allow you to assess if you are at risk or are experiencing violence in any type of relationship.")}</span>

//                 <span>{t("Please select the answer that comes closest to your experiencing over the last 12 months and not just from today.")}</span>

//                 <span className='bold'>{t("*All fields are required.")}</span>
//             </div>

//             <div className='display-flex justify-content-center align-items-left flex-direction-column column-gap-2-rem row-gap-2-rem parent-container-x-margins'>
//                 <h3>{t("Over the last 12 months")}</h3>
//                 <div className='Questions-Container'>
//                     {questionsAndAnswers.map(item =>
//                         <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem'>
//                             <div className='display-flex column-gap-0-5-rem row-gap-0-5-rem'>
//                                 <span className='bold'>{item.index}.</span>
//                                 <span className=''>{i18n.language === "fr" ? item.question.fr : item.question.en}</span>
//                             </div>
//                             <div className={` ${errorTrigger && item.err ? "GBV-Background-Err" : ""} display-flex flex-wrap justify-content-center align-items-center column-gap-2-rem row-gap-2-rem`}>
//                                 {options.map(opt =>
//                                     <span onClick={() => { handleOptionSelect(opt.value, item.index); }} className={`GBV-Option-Button ${(item?.selected === opt.value) ? "background-color-dark-pink color-white" : "background-color-dark-orange"}`}>
//                                         {i18n.language === "fr" ? opt.label.fr : opt.label.en}
//                                     </span>
//                                 )}
//                             </div>
//                             {item.sub_question ?
//                                 <div className='margin-left-3-rem display-flex flex-direction-column column-gap-1-rem row-gap-1-rem'>
//                                     <div className='display-flex column-gap-0-5-rem row-gap-0-5-rem'>
//                                         <span className='bold'>a.</span>
//                                         <span className=''>{i18n.language === "fr" ? item.sub_question.fr : item.sub_question.en}</span>
//                                     </div>
//                                     <div className={`${errorTrigger && item.sub_err ? "GBV-Background-Err" : ""} display-flex flex-wrap justify-content-center align-items-center column-gap-2-rem row-gap-2-rem`}>
//                                         {options.map(opt =>
//                                             <span onClick={() => { handleOptionSelect(opt.value, item.index, true); }} className={`GBV-Option-Button ${(item?.sub_selected === opt.value) ? "background-color-dark-pink color-white" : "background-color-dark-orange"}`}>
//                                                 {i18n.language === "fr" ? opt.label.fr : opt.label.en}
//                                             </span>
//                                         )}
//                                     </div>

//                                 </div>
//                                 : null}
//                         </div>
//                     )}
//                 </div>

//                 <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem justify-content-center with-100-percent margin-top-3-rem align-items-center'>
//                     {errorTrigger ?
//                         <span className='GBV-Background-Err'>Please answer all questions</span>
//                         : null}
//                     {loadedCaptcha &&
//                         <ReCAPTCHA
//                             style={{ display: "inline-block" }}
//                             theme="light"
//                             sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
//                             ref={captchaRef}
//                             onChange={null}
//                             asyncScriptOnLoad={null}
//                         />
//                     }
//                     <span onClick={() => getResult()} className='GBV-Submit-Button'>Get Result</span>
//                 </div>
//                 {scoreResult ?
//                     <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem justify-content-center with-100-percent margin-top-3-rem align-items-center'>
//                         {scoreResult.err ?
//                             <div>
//                                 {scoreResult.map(item =>
//                                     <span className='bold'>{item}</span>
//                                 )}
//                             </div>
//                             : <>
//                                 <div className='GBV-BOX'>
//                                     <div className='display-flex flex-direction-column flex-wrap jutify-content-start column-gap-1-rem row-gap-1-rem'>
//                                         <h1>{t("Results: ")}</h1>
//                                         <span>{scoreResult[0]}</span>
//                                         <span>{scoreResult[1]}</span>
//                                     </div>
//                                     <div className='display-flex flex-direction-column flex-wrap jutify-content-start column-gap-1-rem row-gap-1-rem'>
//                                         <h3>{t("Next Steps -")}</h3>
//                                         <span>{scoreResult[2]}</span>
//                                         <span>{scoreResult[3]}</span>
//                                     </div>
//                                     <div className='display-flex flex-wrap justify-content-space-around align-items-center column-gap-1-rem row-gap-1-rem width-100-psnt'>
//                                         <span className='GBV-BOX-Button'>
//                                             <a target='_blank' rel='noreferrer' href={ReferralForm} className="text-decoration-none color-pink">{t("Referral Form")}</a>
//                                         </span>
//                                         <span className='GBV-BOX-Button'>
//                                             <a target='_blank' rel='noreferrer' href={"https://flourishaccess.ca/map"} className="text-decoration-none color-pink">{t("Access Map")}</a>
//                                         </span>
//                                         <span className='GBV-BOX-Button'>
//                                             <a target='_blank' rel='noreferrer' href={Handbook} className="text-decoration-none color-pink">{t("Recource Booklet")}</a>
//                                         </span>
//                                     </div>
//                                 </div>
//                                 <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem justify-content-center'>
//                                     <span className='font-size-13-px'>{t("Adapted from the WITHWomen – IPV Risk Assessment Tool developed by Dr. Patricia O’Campo from MAP Centre of Urban Health Solutions at St. Michael's Hospital.")}</span>
//                                     <div>
//                                         <span className='font-size-13-px'>{t("Click the links below to explore the WITH suite of apps that can help you.")}</span>
//                                         <ul className='font-size-13-px'>
//                                             <li><span className='bold'>{t("Screening app")}</span> <span>{t("to screen for relationship safety concerns:")}</span> <a target='_blank' rel="noreferrer" href="https://withwomen.ca">https://withwomen.ca</a></li>
//                                             <li><span className='bold'>{t("Pathways")}</span> <span>{t("to plan for safety when in an unsafe relationship:")}</span> <a target='_blank' rel="noreferrer" href="https://withwomenpathways.ca">https://withwomenpathways.ca</a></li>
//                                             <li><span className='bold'>{t("Promise")}</span> <span>{t("to plan for safety when in an unsafe relationship during public health emergencies:")}</span> <a target='_blank' rel="noreferrer" href="https://withwomenpromise.com">https://withwomenpromise.com</a></li>
//                                         </ul>
//                                     </div>
//                                 </div>
//                             </>

//                         }
//                     </div>
//                     : null}

//             </div>

//         </div >
//     }


//     const mainPage = () => {
//         return <div className="GBV-Container parent-container-x-margins">
//             <div><span>{t("Gender-based violence (GBV) refers to any physical, psychological, or otherwise harmful act directed at an individual based on gender. GBV can include physical, verbal, psychological, sexual, financial/economic, digital and spiritual violence inflicted in public and private spaces ")}</span><a target="_blank" rel='noreferrer' href="https://flourishaccess.ca/resources/gbv" className='bold'>{`(${t('Learn More')})`}</a></div>

//             <div className='display-flex flex-wrap align-items-center justify-content-center column-gap-3-rem row-gap-3-rem'>
//                 <div className='display-flex flex-direction-column align-items-center justify-content-center column-gap-2-rem row-gap-2-rem'>
//                     <div className='max-width-30-rem display-flex flex-direction-column align-items-left justify-content-center column-gap-1-rem row-gap-1-rem'>
//                         <span><span className='bold'>{t("Step")}{" 1: "}</span><span>{t("Answer seven quick questions to receive a safety result.")}</span></span>
//                         <span><span className='bold'>{t("Step")}{" 2: "}</span><span>{t("Recognize violence risk level and receive recommendations.")}</span></span>
//                         <span><span className='bold'>{t("Step")}{" 3: "}</span><span>{t("Access resources and/or self-refer for GBV case management.")}</span></span>
//                     </div>
//                     <div className='display-flex flex-direction-column align-items-center justify-content-center column-gap-1-rem row-gap-1-rem'>
//                         <span onClick={() => { setCurrentPage("screen-tool") }} className='GBV-Top-Button'>{t("Start Screening")}</span>
//                         <div className='display-flex flex-wrap align-items-center justify-content-center column-gap-0-5-rem row-gap-0-5-rem'>
//                             <span className='GBV-Bottom-Button'>
//                                 <a target='_blank' rel='noreferrer' href={Handbook} className="text-decoration-none color-white">{t("Resource Booklet")}</a>
//                             </span>
//                             <span className='GBV-Bottom-Button'>
//                                 <a target='_blank' rel='noreferrer' href={ReferralForm} className="text-decoration-none color-white">{t("Referral Form")}</a>
//                             </span>

//                         </div>
//                     </div>
//                 </div>
//                 <div className='display-flex flex-direction-column align-items-center justify-content-center column-gap-2-rem row-gap-2-rem'>
//                     <span className='bold font-size-18-px'>{t("How does it work?")}</span>
//                     <span className='max-width-30-rem'>{t("After you complete the risk screening tool, you will receive information and resources to help you stay safe and free from violence.")}</span>
//                     <span className='font-size-13-px max-width-30-rem'><span className='bold'>{t("Please note: ")}</span><span className='font-size-13-px'>{t("This online screening tool is meant to provide a quick snapshot of your risk of experiencing GBV.")}</span></span>
//                     <div className='max-width-30-rem'>
//                         <span className='font-size-13-px'>{t("This website is not a crisis support line. If you need immediate help or are in imminent danger, you can reach out to:")}</span>
//                         <ul className='margin-top-1-rem'>
//                             <li className='font-size-13-px'><span className='bold'>{t("Assaulted Women's 24/7 Helpline: ")}</span><span>{t("1-866-863-0511 (Multilingual service available)")}</span></li>
//                             <li className='font-size-13-px'><span className='bold'>{t("Victim 24/7 Support Line:  ")}</span><span>1-888-579-2888</span></li>
//                             <li className='font-size-13-px'><span className='bold'>{t("Fem'aide 24/7 Support Line:  ")}</span><span>{t("1-877-336-2433 (French only)")}</span></li>
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//             <div className='display-flex flex-direction-column aling-items-center justify-content-center column-gap-2-rem row-gap-2-rem'>
//                 <span className='bold font-size-30-px'>{t("About GBV Prevention & Care Cascade")}</span>
//                 <span className=''>{t("WHIWH’s prevention and care cascade program that will address the different risks and socio-cultural factors experienced by racialized women (from African, Carribean, Black, Latin American and South Asian communities) and the need for culturally appropriate and safe GBV services.")}</span>
//                 <span className=''>{t("The program is designed to ensure that racialized women receive care for their enduring health needs beyond immediate safety concerns. WHIWH's primary focus is violence prevention, and the program aims to link women at risk or experiencing GBV to care services.")}</span>
//             </div>
//         </div>
//     }

//     const [currentPage, setCurrentPage] = useState("main-page");
//     const renderPage = {
//         "main-page": mainPage(),
//         "screen-tool": screeningTool()
//     }

//     return (
//         <div className="GBV_Parent_Container">
//             {/* <img src={topImg} alt="thirve" className="Home-Block-Image-0 image-motion-2 image-m-2" /> */}
//             <div className="img-wrap">
//                 {/* <img src={backgroundImg} alt="background flourish access website" className="flourish-background-img" /> */}
//                 {renderPage[currentPage]}
//             </div>
//         </div>
//     );
// }



