import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./resources.css";
import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import Videos from "./videos.component/videos.component";
import DigitalStories from "./digital-stories.component/digitalStories.component";
import Other from "./other.component/other.component";
import Gbv from "./gbv.component/gbv.component";
import Blogs from "./blogs.component/blogs.component";
import GbvPodcast from "./gbv-podcast.component/gbvPodcast.component";
import GbvRiskAssessment from "./gbvRiskAssessment.component/gbvRiskAssessment.component";

import { BiBookOpen} from "react-icons/bi";
import { FaBlog } from "react-icons/fa";
import { FiVideo } from "react-icons/fi";
import { CgDetailsMore } from "react-icons/cg";
import { BsGenderAmbiguous } from "react-icons/bs";
import topImg from "../../assets/images/Re-design assets/Copy of Resources Banner.png"
import backgroundImg from "../../assets/images/Re-design assets/Copy of Main background design.png";
import { SiGooglepodcasts } from "react-icons/si";
import { RiSurveyLine } from "react-icons/ri";

function Resources(params) {
    const { selected } = useParams();
    const [selection, setSelection] = useState("");
    const canLoad = useRef(true);
    const { t } = useTranslation();

    const resourcesPages = { "gbv": <Gbv />, "digital-stories": <DigitalStories />, "blogs": <Blogs />, "videos": <Videos />, "gbv-podcast": <GbvPodcast />, "gbv-risk-assessment": <GbvRiskAssessment />, "additional-information": <Other /> }
    const resourcesIcons = { "gbv": <BsGenderAmbiguous />, "digital-stories": <BiBookOpen />, "blogs": <FaBlog />, "videos": <FiVideo />, "gbv-podcast": <SiGooglepodcasts />, "gbv-risk-assessment": <RiSurveyLine /> , "additional-information": <CgDetailsMore /> }

    useEffect(() => {
        if (canLoad.current) {
            canLoad.current = false;
            //console.log("loading");
            setSelection(selected);
        } else {
            canLoad.current = true;
        }
    }, [selected, selection])


    return (
        <div className="Resources_Parent_Container">
            <img src={topImg} alt="thirve" className="Home-Block-Image-0 image-motion-2 image-m-2" />
            <div className="img-wrap">
                <img src={backgroundImg} alt="background flourish access website" className="flourish-background-img" />
                <div className="parent-container-x-margins Resources-Container">

                    <div className="Left-Navigation-Bar">
                        {Object.keys(resourcesPages).map((item, index) => (
                            <Link to={`/gbv-resources/${item}`} className={`text-decoration-none color-black hover-background-color-light-pink border-radius-5-px padding-y-0-5-rem padding-x-0-5-rem Selected-Resources-Item ${selected === item ? "Rescources-Selected" : ""}`} key={`link+${index}`} >
                                <div className="display-flex flex-direction-row align-items-center  row-gap-0-5-rem column-gap-0-5-rem">
                                    <span className="Resources-Selection-Icon">
                                        {resourcesIcons[item]}
                                    </span>
                                    <span className="cursor-pointer text-decoration-none color-black " key={item + index}>{t(item)}</span>
                                </div>
                            </Link>
                        ))}
                    </div>
                    <div className="Selected-Resources-Container">
                        {resourcesPages[selected]}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Resources;