import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";



// import typesOfAbuseImg from "../../../assets/images/resources/gbv/Types of abuse.png";

function Gbv(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();


    useEffect(() => {
        if (canLoad.current) {
            //console.log(i18n.language)
        } else {

        }
    }, [])

    return (
        <div className="display-flex flex-direction-column flex-wrap min-width-15-rem row-gap-3-rem column-gap-3-rem">
            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-24-px bold">{t("resources-page.gbv.header1")}</span>
                <div> <span className="bold">{t("resources-page.gbv.text1.1")}</span>{" "+t("resources-page.gbv.text1.2")+" ("}
                    <a href="https://www.unhcr.org/gender-based-violence.html" target="_blank" rel="noreferrer">{t("UNCHR")}</a>{"). " + t("resources-page.gbv.text1.3") + " ("}<a href="https://www.who.int/news-room/fact-sheets/detail/violence-against-women#:~:text=Health%20consequences&text=This%20violence%20leads%20to%20high,of%20this%20violence%20(3)." target="_blank" rel="noreferrer">{t("WHO")}</a>{", 2011)"}
                </div>
              
            </div>

            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap text-center">
                <div>
                <span className=" bold">{t("resources-page.gbv.header2")}</span>{" ("}<a href="https://women-gender-equality.canada.ca/en/gender-based-violence-knowledge-centre/about-gender-based-violence.html" target="_blank" rel="noreferrer">{t("Women and Gender Equality Canada")}</a>{", 2022)."}

                </div>
            </div>

   

            
            <div className="display-flex flex-direction-column flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.gbv.header3")}</span>
                <span className="">{t("resources-page.gbv.text3.1") +", "}
                    <a href="https://www.bwss.org/wp-content/uploads/lifetime-spiral-violence.gif" target="_blank" rel="noreferrer">{t("age")}</a>{", " + t("resources-page.gbv.text3.2") + " "}<a href="https://canadianwomen.org/the-facts/gender-based-violence/" target="_blank" rel="noreferrer">{t("gender")}</a>{"."}
                </span>
                <span className="margin-top-0-5-rem">{t("resources-page.gbv.text3.3") + " "}
                    <a href="https://www.vawlearningnetwork.ca/our-work/issuebased_newsletters/issue-26/index.html" target="_blank" rel="noreferrer">{"immigrant and refugee"}</a>{" " + t("resources-page.gbv.text3.4")}
                </span>
            </div>

            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap text-center">
                <div>
                    <span className=" bold">{t("resources-page.gbv.text3.5")}</span>{" ("}<a href="https://www150.statcan.gc.ca/n1/pub/85-002-x/2021001/article/00008-eng.htm" target="_blank" rel="noreferrer">{t("Statistics Canada")}</a>{", 2021)."}
                </div>
            </div>

            <div className="display-flex flex-direction-column  flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.gbv.header4")}</span>
                <span className="">{t("resources-page.gbv.text4")}</span>
 
            </div>

            {/* <div className="display-flex flex-direction-column text-center">
                <img src={typesOfAbuseImg} alt="types of abuse" className="min-width-15-rem max-width-40-rem mobile-image" />
            </div> */}

            <div className="display-flex flex-direction-column  flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.gbv.header5")}</span>
                <span className="">{t("resources-page.gbv.text5.1")}</span>

            </div>
            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap text-center">
                <div>
                    <span className=" bold">{t("resources-page.gbv.text5.2") }</span>
                    {" ("}<a href="https://www.endvawnow.org/en/articles/301-consequences-and-costs-.html" target="_blank" rel="noreferrer">{t("UN Women")}</a>{", 2010)."}
                </div>
            </div>


            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-20-px bold">{t("resources-page.gbv.header6")}</span>
                <ol>
                    <li>
                        <span className="">{t("resources-page.gbv.text6.1.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.1.1")}</span>
                            </li>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.1.2")}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="">{t("resources-page.gbv.text6.2.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.2.1")}</span>
                            </li>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.2.2")}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="">{t("resources-page.gbv.text6.3.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.3.1")}</span>
                            </li>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.3.2")}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="">{t("resources-page.gbv.text6.4.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.4.1")}</span>
                            </li>
                            <li>
                                <span className="">{t("resources-page.gbv.text6.4.2")}</span>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>

            
            <div className="display-flex flex-direction-column text-center">
                <span className="font-size-20-px font-family-caveat">{t("resources-page.gbv.header7")}</span>
            </div>

        </div>
    )
}
export default Gbv;