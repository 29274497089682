import axios from "axios";
import { appConstants } from "../constants/appConstants";
import Cookies from 'universal-cookie';

const cookies = new Cookies();
// function insertImage(imageObject) {
//     return new Promise((resolve, reject) => {
//         try {
//             axios.post(`${appConstants.BASE_URL}/${"sqlInsertImage"}`, imageObject).then(data => {
//                 console.log("data stored ", data);
//                 resolve(data);
//             }).catch(err => {
//                 console.log("someting wend wrong ", err);
//                 reject(err);
//             })
//         }
//         catch (err) {
//             reject(err);
//         }
//     })
// }

function getImages() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetImages"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}


function getPins() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetPins"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getPodcasts() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetPodcasts"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getGBVPodcasts() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"getGBVPodcasts"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getHomeTickets() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetHomeTickets"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getResourcesVideos() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetResourcesVideos"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getGbvResourcesVideos() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"getGbvResourcesVideos"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getResourcesLinks() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetResourcesLinks"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getGbvResourcesLinks() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"getGbvResourcesLinks"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getResourcesWebinars() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetResourcesWebinars"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}


function getSurveyQuestions() {
    return new Promise(async (resolve1, reject1) => {
        try {
            //console.log(appConstants.BASE_URL);
            axios.get(`${appConstants.BASE_URL}/${"sqlGetSurveyQuestions"}`).then(data => {
                //console.log(data.data);
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function uploadSurveyQuestion(obj) {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.post(`${appConstants.BASE_URL}/surveyQuestion`, { "surveyQuestion": obj }).then(data => {
                console.log(data);
                const imageObject = data.data.imageObject;
                console.log(imageObject)
                resolve1(imageObject)

            }).catch(err => {
                resolve1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

function uploadMedia(obj) {
    return new Promise(async (resolve1, reject1) => {
        try {
            let cookie = cookies.get("admin-user-cookie");
            if (!cookie) {
                reject1({ "err": "unuthorized" })
            }
            let [email, adminCookie] = cookie.split("%");
            axios.post(`${appConstants.BASE_URL}/admin/uploadImage`, { "mediaObject": obj, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
                console.log(data);
                const imageObject = data.data.imageObject;
                console.log(imageObject)
                resolve1(imageObject)

                // let ds = document.getElementById("gg");
                // ds.src = fileLocataion;
            }).catch(err => {
                resolve1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

// function uploadTicket(obj) {
//     return new Promise(async (resolve1, reject1) => {
//         try {
//             let cookie = cookies.get("admin-user-cookie");
//             if (!cookie) {
//                 reject1({ "err": "unuthorized" })
//             }
//             let [email, adminCookie] = cookie.split("%");
//             axios.post(`${appConstants.BASE_URL}/admin/uploadTicket`, { "mediaObject": obj, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
//                 console.log(data);
//                 const imageObject = data.data.imageObject;
//                 console.log(imageObject)
//                 resolve1(imageObject)

//                 // let ds = document.getElementById("gg");
//                 // ds.src = fileLocataion;
//             }).catch(err => {
//                 resolve1(err)
//             })
//         }
//         catch (err) {
//             reject1(err)
//         }
//     })

// }


function updatePin(obj, id) {
    return new Promise(async (resolve1, reject1) => {
        try {
            let cookie = cookies.get("admin-user-cookie");
            if (!cookie) {
                reject1({ "err": "unuthorized" })
            }
            let [email, adminCookie] = cookie.split("%");

            axios.put(`${appConstants.BASE_URL}/admin/updatePin`, { "pinObject": obj, "pin_id": id, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
                console.log(data);
                const imageObject = data.data.message;
                console.log(imageObject)
                resolve1(imageObject)
            }).catch(err => {
                resolve1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

function addPin(obj) {
    return new Promise(async (resolve1, reject1) => {
        try {
            let cookie = cookies.get("admin-user-cookie");
            if (!cookie) {
                reject1({ "err": "unuthorized" })
            }
            let [email, adminCookie] = cookie.split("%");
            axios.post(`${appConstants.BASE_URL}/admin/addPin`, { "pinObject": obj, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
                console.log(data);
                const d = data.data;
                console.log(d)
                resolve1(d)
            }).catch(err => {
                resolve1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

function removePin(id) {
    return new Promise(async (resolve1, reject1) => {
        try {
            let cookie = cookies.get("admin-user-cookie");
            if (!cookie) {
                reject1({ "err": "unuthorized" })
            }
            let [email, adminCookie] = cookie.split("%"); //TODO
            axios.post(`${appConstants.BASE_URL}/admin/removePin`, { "pin_id": id, "adminCredentials": { "email": email, "cookie": adminCookie } }).then(data => {
                console.log(data);
                const d = data.data;
                console.log(d)
                resolve1(d)
            }).catch(err => {
                resolve1(err)
            })
        }
        catch (err) {
            reject1(err)
        }
    })

}

function addSurveyAnswers(obj) {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.post(`${appConstants.BASE_URL}/addSurveyAnswers`, { "data": obj }).then(data => {
                resolve1(data)
            }).catch(err => {
                reject1(err);
            })
        }
        catch (err) {
            reject1(err)
        }
    })
}

function getSurveyAnswers() {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/getSurveyAnswers`).then(data => {
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getAllBlogs() {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/getAllBlogs`).then(data => {
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getDigitalStories() {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/getDigitalStories`).then(data => {
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getGbvDigitalStories() {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/getGbvDigitalStories`).then(data => {
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

function getScreenToolAnswers() {
    return new Promise(async (resolve1, reject1) => {
        try {
            axios.get(`${appConstants.BASE_URL}/getScreenToolAnswers`).then(data => {
                resolve1(data.data);
            }).catch(err => {
                console.log("someting wend wrong ", err);
                reject1(err);
            })
        }
        catch (err) {
            reject1(err);
        }
    })
}

const mysqlServices = {
    // insertImage,
    getImages,
    uploadMedia,
    getPins,
    updatePin,
    addPin,
    removePin,
    getPodcasts,
    getGBVPodcasts,
    getSurveyQuestions,
    uploadSurveyQuestion,
    // uploadTicket,
    getHomeTickets,
    getResourcesVideos,
    getGbvResourcesVideos,
    getResourcesLinks,
    getGbvResourcesLinks,
    getResourcesWebinars,
    addSurveyAnswers,
    getSurveyAnswers,
    getAllBlogs,
    getDigitalStories,
    getGbvDigitalStories,
    getScreenToolAnswers
}

export default mysqlServices