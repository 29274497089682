import React, { useState, useEffect, useRef, useContext } from 'react';
// import {helloServices} from "../../services/helloServices";
import { useTranslation } from 'react-i18next';
import "./home.css";
// import mapImg from "../../assets/images/home/map diagram1.png";
// import thirveImg from "../../assets/images/home/thrive diagram.png";
import { Link } from "react-router-dom";
// import genernetImg from "../../assets/images/home/gendernet.png"

// import africaMap from "../../assets/images/resources/aboutFGM/fgm prevalence.PNG";
// import learnMore from "../../assets/images/home/learn more.PNG";
import learnMore1 from "../../assets/images/Re-design assets/Over 200 million.png";
// import partnership from "../../assets/images/home/Partnership.png";
// import mapImg1 from "../../assets/images/home/map.PNG";
import DOMPurify from 'dompurify';
import { AdminContext } from "../../App";
import DisplayContent from '../admin.component/displayMedia';
import mysqlServices from '../../services/mysqlServices';
import topImg from "../../assets/images/Re-design assets/Copy of Home Banner.png";

function Home() {
    const adminState = useContext(AdminContext)

    const ref = useRef(true);
    const { t, i18n } = useTranslation();

    const [tickets, setTickets] = useState(null);

    // const navigate = useNavigate();
    useEffect(() => {
        if (ref.current) {
            //console.log(document.getElementById("cs"))
            // the strict mode in React 18 will mount unmount and remount the component whihc is why we needed to prevent that
            //console.log("s")
            ref.current = false;

            mysqlServices.getHomeTickets().then(data => {
                let tickets = data.data;
                //console.log(tickets)
                setTickets(tickets);

            }).catch(err => {
                console.log(err);
            })

        }
    }, [])

    // function handleNavigate(page) {
    //     navigate(`/${page}`)
    // }

    const eBulletin = `
    <div class="min-width-200-px-max-width-400-px">
    <h4 class="width-fit">E-Bulletin</h4>
    <p class="width-fit">Subscribe to our mailing list to stay informed about project news, updates and upcoming activities.</p>


    <link href="//cdn-images.mailchimp.com/embedcode/classic-071822.css" rel="stylesheet" type="text/css">
    <style type="text/css">
	#mc_embed_signup{background:#fff; clear:left; font:14px Helvetica,Arial,sans-serif;  width:width-fit;}
	/* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
	   We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
    </style>
    <div id="mc_embed_signup">
    <form action="https://flourishaccess.us21.list-manage.com/subscribe/post?u=3bb5e21440c7548e8510c0eec&amp;id=4842b6fd79&amp;f_id=00effce1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" target="_blank" novalidate>
        <div id="mc_embed_signup_scroll">
        
        <div class="indicates-required"><span class="asterisk">*</span> indicates required</div>
    <div class="mc-field-group">
	<!--<label for="mce-EMAIL">Email Address  <span class="asterisk">*</span></label>-->
	<input type="email" value="" name="EMAIL" class="required email" id="mce-EMAIL" placeholder="Email Address *" required>
	<span id="mce-EMAIL-HELPERTEXT" class="helper_text"></span>
    </div>
    <div class="mc-field-group">
	    <!--<label for="mce-FNAME">First Name </label>-->
	    <input type="text" value="" name="FNAME" class="" id="mce-FNAME" placeholder="First Name">
	    <span id="mce-FNAME-HELPERTEXT" class="helper_text"></span>
    </div>
    <div class="mc-field-group">
	    <!--<label for="mce-LNAME">Last Name </label>-->
	    <input type="text" value="" name="LNAME" class="" id="mce-LNAME" placeholder="Last Name">
	    <span id="mce-LNAME-HELPERTEXT" class="helper_text"></span>
    </div>
    <div hidden="true"><input type="hidden" name="tags" value="300904"></div>
	    <div id="mce-responses" class="clear foot">
		<div class="response" id="mce-error-response" style="display:none"></div>
		<div class="response" id="mce-success-response" style="display:none"></div>
	</div>    <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
    <div style="position: absolute; left: -5000px;" aria-hidden="true"><input type="text" name="b_3bb5e21440c7548e8510c0eec_4842b6fd79" tabindex="-1" value=""></div>
        <div class="optionalParent">
            <div class="clear foot">
                <input type="submit" value="Subscribe" name="subscribe" id="mc-embedded-subscribe" class="button">
                <p class="brandingLogo"><a href="http://eepurl.com/ikrHuv" title="Mailchimp - email marketing made easy and fun"><img src="https://eep.io/mc-cdn-images/template_images/branding_logo_text_dark_dtp.svg"></a></p>
            </div>
        </div>
    </div>
    </form>
    </div>
<script type='text/javascript' src='//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js'></script><script type='text/javascript'>(function($) {window.fnames = new Array(); window.ftypes = new Array();fnames[0]='EMAIL';ftypes[0]='email';fnames[1]='FNAME';ftypes[1]='text';fnames[2]='LNAME';ftypes[2]='text';fnames[3]='ADDRESS';ftypes[3]='address';fnames[4]='PHONE';ftypes[4]='phone';fnames[5]='BIRTHDAY';ftypes[5]='birthday';}(jQuery));var $mcj = jQuery.noConflict(true);</script>
</div>
`;

    return (
        <div className='Home-Container'>
            <img src={topImg} alt="thirve" className="Home-Block-Image-0 image-motion-2 image-m-2" />
            <div className='background-flourish'>


                <div className='Home-Block-2'>
                    <div className='Home-Block align-items-center '>
                        <div className='font-size-16-px width-60-percent flex-grow-1'>
                            <span className='font-size-24-px bold'>{"Flourish "}</span>
                            {t('home-text.text1-body.1')}
                        </div>
                    </div>

                    <div className='Home-Block align-items-center '>
                        <div className='font-size-16-px width-60-percent flex-grow-1'>
                            <span className='font-size-18-px bold'>{t('home-text.text1+-body.1')}</span>
                            {t('home-text.text1+-body.2')}
                        </div>

                    </div>

                    <div className='Home-Block width-100-percent '>
                        <div className='font-size-16-px  flex-grow-1'>
                            <span className='font-size-18-px bold flex-grow-1 width-100'>{t('home-text.text1++-body.1')}</span>
                            {t('home-text.text1++-body.2')}
                        </div>

                    </div>



                    <div className='display-flex flex-wrap align-items-center justify-content-center'>
                        <span className='text-decoration-none width-fit'>
                            <Link to={"/resources/fgm"} target="_blank" rel="noreferrer" className=' width-fit'>
                                <img src={learnMore1} alt="thirve" className="Home-Block-Image-0-1 mobile-image hover-border-dotted" />
                            </Link>
                        </span>

                    </div>


                    <div className="display-flex flex-direction-column text-center">
                        <span className="font-size-20-px font-family-caveat">{t("home-text.text2-body+.1")}</span>
                        {/* <span className="bold">{"-" + t("home-text.text2-body+.2")}</span> */}
                    </div>

                    <span className=' text-decoration-none flex-grow-1 '>
                        {" " + t('home-text.text2-body.2*')}
                        <Link to={"/map"} target="_blank" rel="noreferrer" ><span className='font-size-20-px bold cursor-pointer hover-background-color-light-pink text-decoration-none color-black'>{" " + t("map")}</span></Link>
                        {" " + t('home-text.text2-body.3')}
                    </span>
                </div>
            </div>


            {tickets ? <div className='Tickets-Container'>

                {tickets.map(item =>
                    <div className='display-flex flex-direction-column flex-wrap Home-Ticket-Image'>
                        <img className='Home-Ticket-Image border-radius-5-px' src={item.src} alt={item.src} />
                        <span className='bold'>{item.header}</span>
                        <span className=''>{item.paragraph}</span>
                    </div>
                )}

            </div> : null}



            <div className='Home-Block-3-Wrap'>
                <div className='Home-Block-3 align-items-center'>
                    <div className='font-size-16-px display-flex flex-direction-column row-gap-1-rem column-gap-1-rem'>
                        <div className='display-flex flex-direction-row  align-items-center row-gap-1-rem column-gap-1-rem flex-wrap '>
                            {/* <span className='text-decoration-none width-fit'>
                                <img src={partnership} alt="partnership" className='Home-Block-Image-2  mobile-image' />
                            </span> */}
                            <span className='font-size-18-px min-width-200-px-max-width-600-px text-decoration-none flex-grow-1'>
                                {t("home-text.text3-body.1")}
                                <span className=' bold'>{" " + t("home-text.text3-body.2")}</span>
                                {" " + t("home-text.text3-body.3")}
                                <span className=' bold'>{" " + t("home-text.text3-body.4")}</span>
                                <br />
                                <br />
                                {" " + t("home-text.text3-body.5")}
                                {" "}<a href='https://gender-net-plus.eu/' target="_blank" rel="noreferrer" className='hover-background-color-light-pink'>{"https://gender-net-plus.eu/"}</a>
                                {" " + t("home-text.text3-body.6")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <div className="Bulletin" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(eBulletin) }}></div>

         


        </div>
    );
}

export default Home;