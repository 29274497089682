
import Home from './components/home.component/home.component';
import MapFunction from './components/map.component/map.component';
import Header from './components/header.component/header.component';
import NoMatch from './components/noMatch.component/noMatch.component';
import Resources from './components/resources.component/resources.component';
import Contact from './components/contact.component/contact.component';
import About from './components/about.component/about.component';
import Footer from './components/footer.component/footer.component';
import AdminPage from './components/admin.component/adminPage.component';
import ImagesPage from './components/admin.component/imagesPage.component';
import CoverYourTrack from './components/coverYourTrack.component/coverYourTrack';
import Cookies from 'universal-cookie';
import Landing from './components/landing.component/landing.component';
import FgmResources from './components/fgm-resources.component/resources.component';

import {
  BrowserRouter,
  Routes,
  Route,
  // Link,
  // Navigate,
  // HashRouter,
  // createBrowserRouter,
  // RouterProvider,
  // createRoutesFromElements
} from "react-router-dom";
import { Suspense, lazy, useRef, useEffect, createContext, useState } from "react";


// const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE,{
//   debug_mode:true
// });

 export const AdminContext = createContext({ isAdminLoggedIn: "false" });


function App() {
  // const loaded = useRef(false);
  // const ga = useGA4React();
  // console.log(ga);
  // if you want the debugging to work then do then do this
  /**
   *   [
        {
          trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE,
          debug: true,
          alwaysSendToDefaultTracker: false
        }
      ]
   */
  // const setGA = async ()=> {
  //   console.log("analytics", process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE, window.location.pathname + window.location.search)
  //   // ReactGA.initialize(
  //   //    [
  //   //     {
  //   //       trackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE,
  //   //       gaOptions: {
  //   //         debug: true,
  //   //         alwaysSendToDefaultTracker: false
  //   //       }
  //   //     }
  //   //   ]
  //   // //   process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE, {
  //   // //   debug: true,
  //   // //   alwaysSendToDefaultTracker: false 
  //   // // }
  //   // );
  //    await ga4react.initialize().then((ga4)=>{
  //     console.log(ga4)
  //     // ga4.pageview(window.location.pathname + window.location.search)
  //     ga4.gtag('event','pageview', window.location.pathname + window.location.search) // or your custom gtag event
  //   }).catch(err => {
  //     console.log(err);
  //   })

  // }
  // useEffect(()=>{
  //   if(!loaded.current){
  //     loaded.current = true;
  //     setGA()
  //   }
  // },[])




  const [adminLoggedIn, setAdminLoggedIn] = useState("false");

  const handleAdminLogin = (value)=>{
    console.log("settting",value)
    setAdminLoggedIn(value)
  }


  useEffect(() => {
    // const state = localStorage.getItem("admin-user");
    const cookies = new Cookies();
    const cookieState = cookies.get('admin-user-cookie');
    // if (state) {
    //   setAdminLoggedIn(state)
    // }
    if (cookieState) {
      setAdminLoggedIn("true")
    }
  }, []);

  return (
  <AdminContext.Provider value={adminLoggedIn}>  
  <BrowserRouter>
    <Header />
    <Suspense fallback={<div>Loading ...</div>}>
      <Routes>
        <Route path="*" element={<NoMatch />} />
        <Route path="/" element={<Landing />} />  
        <Route path="/home" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/map" element={<MapFunction />} />
        <Route path="/gbv-resources/:selected" element={<Resources />} />
        <Route path="/fgm-resources/:selected" element={<FgmResources />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/admin-page" element={<AdminPage setAdminLoggedIn={handleAdminLogin} />} />
        <Route path="/images-page" element={<ImagesPage />} />
        <Route path="/cover-your-track" element={<CoverYourTrack />} />

        
      </Routes>
    </Suspense>
    <Footer />
    </BrowserRouter>
  </AdminContext.Provider>
  );
}

export default App;
// export default router;