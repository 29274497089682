import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

import fgmNamesImg from "../../../assets/images/Re-design assets/FGM names.png";
import fgmPrevalenceImg from "../../../assets/images/resources/aboutFGM/FGM prevalence 2.png";
import percentage from "../../../assets/pdf/Global Percentage of Women Living with FGM.pdf";


function Fgm(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();

    let names =  ["Thara",  	"Khitan",  	"Megrez",  	"Mekhnishab", 		"Kutairi", 		"Ibi/Ugwu",  	"Bondo/Sonde", 		"Gudiniin", 	"Qodiin", 		"Tahoor", 	"Bagne", 		"Gadja","Sunna", 	"Musolula", "Karoola", 	"Fanadu di Mindjer", 	"Niaka", 		"Kuyango" 
    ]


    useEffect(() => {
        if (canLoad.current) {
            //console.log(i18n.language)
        } else {

        }
    }, [])

    return (
        <div className="display-flex flex-direction-column flex-wrap min-width-15-rem row-gap-3-rem column-gap-3-rem">
            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-24-px bold">{t("resources-page.fgm.header1")}</span>
                <div> <span className="font-size-18-px bold">{t("resources-page.fgm.text1-1")}</span>{" "+t("resources-page.fgm.text1-2")+" ("}
                    <a href="https://www.who.int/health-topics/female-genital-mutilation#tab=tab_1" target="_blank" rel="noreferrer">{t("WHO")}</a>{", "}<a href="https://www.unicef.org/protection/female-genital-mutilation" target="_blank" rel="noreferrer">{t("UNICEF")}</a>
                    {", "}<a href="https://www.unfpa.org/female-genital-mutilation" target="_blank" rel="noreferrer">{t("UNFPA")}</a>
                    {", 1997). " + t("resources-page.fgm.text1-3")+" "}<span className="bold">{t("resources-page.fgm.text1-4")+" "}</span>
                    {t("resources-page.fgm.text1-5") + " "}<span className="bold">{t("resources-page.fgm.text1-6") + " "}</span>
                    {t("resources-page.fgm.text1-7") + " ("}
                    <a href="https://www.unfpa.org/resources/female-genital-mutilation-fgm-frequently-asked-questions" target="_blank" rel="noreferrer">{t("resources-page.fgm.text1-8")}</a>{")."}
                </div>
            </div>

            <div className="display-flex flex-direction-column text-center">
                <span className="font-size-20-px font-family-caveat">{t("resources-page.fgm.text2")}</span>
            </div>

            <div className="display-flex flex-direction-column text-center">
                <span className="bold">{t("resources-page.fgm.text3")}</span>
            </div>

            <div className="display-flex flex-wrap justify-content-center">
                <span className="width-fit display-flex">
                    <img src={fgmNamesImg} className="image-fgm mobile-image" alt="map of africa FGM/C prevalence" />
                </span>
            </div>

            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.fgm.header4")+" "}<span className="font-size-14-px">{"("}<a href="https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/areas-of-work/female-genital-mutilation/types-of-female-genital-mutilation" target="_blank" rel="noreferrer">{t("WHO")}</a>{", 2007)."}</span></span>
                <div className="display-flex flex-direction-column">
                    <span className="">{t("type") + " 1. "}<span className="bold">{t("clitoridectomy") + " "}</span>{t("resources-page.fgm.text4-1")}</span>
                    <span className="">{t("type") + " 2. "}<span className="bold">{t("incision") + " "}</span>{t("resources-page.fgm.text4-2")}</span>
                    <span className="">{t("type") + " 3. "}<span className="bold">{t("infibulation") + " "}</span>{t("resources-page.fgm.text4-3")}</span>
                    <span className="">{t("type") + " 4. "}<span className="bold">{t("other") + " "}</span>{t("resources-page.fgm.text4-4")}</span>
                </div>
            </div>

            <div className="display-flex flex-direction-column text-center">
                <span className="font-size-20-px font-family-caveat">{t("resources-page.fgm.text5")}</span>
            </div>


            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.fgm.header6")}</span>
                <span className="">{t("resources-page.fgm.text6-1")} <a href="https://www.who.int/teams/sexual-and-reproductive-health-and-research-(srh)/areas-of-work/female-genital-mutilation/health-risks-of-female-genital-mutilation" target="_blank" rel="noreferrer">{t("learn-more")}</a>{" " + t("resources-page.fgm.text6-3")}</span>
            </div>

            <div className="display-flex flex-direction-column text-center">
                <span className="font-size-20-px font-family-caveat">{t("resources-page.fgm.text7")}</span>
            </div>

            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.fgm.header8")}</span>
                <span className="">{t("resources-page.fgm.text8-1") + " ("}<a href="https://data.unicef.org/topic/child-protection/female-genital-mutilation/" target="_blank" rel="noreferrer">{t("UNICEF")}</a>{"). " + t("resources-page.fgm.text8-2")+" ("}
                <a href="https://www.unfpa.org/resources/impact-covid-19-pandemic-family-planning-and-ending-gender-based-violence-female-genital" target="_blank" rel="noreferrer">{"UNFPA"}</a>{", 2020). " + t("resources-page.fgm.text8-3")}
                </span>
            </div>


            <div className="display-flex flex-wrap justify-content-center">
                <span className="font-size-18-px bold">{t("resources-page.fgm.header9")}</span>
                <span className="width-fit display-flex cursor-pointer" onClick={()=> {window.open(percentage)}}>
                    <img src={fgmPrevalenceImg} className="image-fgm mobile-image hover-border-dotted" alt="map of africa FGM/C prevalence" />
                </span>
            </div>


            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.fgm.header10") + " "}<span className="font-size-14-px">{"("}<a href="https://www.ohrc.on.ca/en/policy-female-genital-mutilation-fgm/4-fgm-canada" target="_blank" rel="noreferrer">{t("learn-more")}</a>{")."}</span></span>
                <span className="">{t("resources-page.fgm.text10")}</span>
            </div>

            <div className="display-flex flex-direction-column text-center">
                <span className="font-size-20-px font-family-caveat">{t("resources-page.fgm.text11")}</span>
            </div>


       
            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.fgm.header12")}</span>
                <ol>
                    <li>
                        <span className="">{t("resources-page.fgm.text12-1.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.fgm.text12-1.1")}</span>
                            </li>
                            <li>
                                <span className="">{t("resources-page.fgm.text12-1.2")}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="">{t("resources-page.fgm.text12-2.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.fgm.text12-2.1")}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="">{t("resources-page.fgm.text12-3.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.fgm.text12-3.1")}</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <span className="">{t("resources-page.fgm.text12-4.head")}</span>
                        <ul>
                            <li>
                                <span className="">{t("resources-page.fgm.text12-4.1")}</span>
                            </li>
                            <li>
                                <span className="">{t("resources-page.fgm.text12-4.2")}</span>
                            </li>
                        </ul>
                    </li>
                </ol>
            </div>



            <div className="display-flex flex-direction-column text-center">
                <span className="font-size-20-px font-family-caveat">{t("resources-page.fgm.text13")}</span>
            </div>

        </div>
    )
}
export default Fgm;