import React, { useEffect, useState } from 'react'
// import { isSafariBrowser } from "../../../../utils/commonFunc";
// import redDelete from '../../../../assets/images/X.svg';
import redDelete from "../../assets/images/logos/WAGE-FEGC-R-e.png";


const DisplayVideo = (src, del) => {
    return (
        <>
            {del &&
                <div className={"profile-page-edit-container absolute scale-8 right-button-position pointer z-10"} onClick={() => { del() }}>
                    <div className={"delete-btn-icon delete-video-icon deleteIconSize"}>
                        <img
                            src={redDelete}
                            className={"edit-button delete-video-icon"}
                            alt="Delete Video"
                            title="Delete Video"
                        />
                    </div>
                </div>
            }
            {
                src.substr(src.length - 4) === ".avi" ? (
                    <embed src={src} type="video/*" className={'about-section video-fill'}></embed>
                    ) : (
                    <video playsInline className={"height-100 width-100 black-bgcolor round-border mt-4"} src={src} controls muted="muted">
                        Your browser does not support the video tag.
                    </video>
                )
            }
        </>
    )
}

const DisplayImage = (src, del) => {
    return (
        <>
            {del &&
                // <div className={"profile-page-edit-container absolute scale-8 right-button-position pointer z-10"} onClick={() => { del() }}>
                //     <div className={"delete-btn-icon delete-video-icon deleteIconSize"}>
                //         <img
                //             src={redDelete}
                //             className={"edit-button delete-video-icon"}
                //             alt="Delete Video"
                //             title="Delete Video"
                //         />
                //     </div>

                // </div>
                <div className={"addProcessDelete item pointer unselectable"} onClick={() => { del() }}><img className={"undraggable"} src={redDelete} /></div>


            }
            {
                <img className={"height-100 width-100 radius-10 "} src={src} controls muted="muted" />
            }
        </>
    )
}
const DisplayContent = (props) => {
    const { src, del } = props;
    const [data, setData] = useState(null)




    return DisplayImage(src, del)

   

    // if (src && src.includes && src.includes('/videos')) {
    //     return DisplayVideo(src, del)
    // }
    // else if (src && src.url) {
    //     return DisplayVideo(src.url, del)
    // }
    // else {
    //     return DisplayImage(src, del)
    // }
}

export default DisplayContent;