import React from 'react';
import { useTranslation } from 'react-i18next';
function NoMatch(props){
    const { t, i18n } = useTranslation();
    return (
        <div className='text-center margin-top-3-rem font-size-20-px background-color-light-pink height-fit'>
            {t("404-error")}
        </div>
    )
}

export default NoMatch;