import React from "react";
import whiwhImg from "../../assets/images/logos/WHIWH logo- landscape Large.png"
import { useTranslation } from "react-i18next";

function WhiwhContact(props){
    const { t, i18n } = useTranslation();
    return (
        <div className='backround-img-yellow display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem text-center justify-content-center align-items-center padding-bottom-2-rem padding-top-2-rem'>
            {/* <hr
                style={{
                    background: '#ac94c4',
                    color: '#ac94c4',
                    borderColor: '#ac94c4',
                    height: '3px',
                    width: '100%'
                }}
            /> */}
            <span className='bold'>{t('about-page.body.block5.text1')}</span>
            <a className="text-decoration-none color-dark-pink bold" href="https://www.whiwh.com/" target="_blank" rel="noreferrer" >https://www.whiwh.com/</a>
            <a className="text-decoration-none color-dark-pink bold" href="https://www.whiwhresearch.com/" target="_blank" rel="noreferrer">https://www.whiwhresearch.com/</a>
            {/* <img src={whiwhImg} alt="thirve" className='About-Image-Logo float-right mobile-image' /> */}
            <div className="display-flex flex-wrap row-gap-4-rem column-gap-4-rem justify-content-center">
                <div>
                    <span className="bold">{"2 Carlton Street, Suite 500"}</span>
                    <br />
                    <span className="bold">{"Toronto, ON M5B 1J3"}</span>
                    <br />
                    <span className="bold">{"(T) 416-593-7655"}</span>
                    <br />
                    <a className="text-decoration-none color-dark-pink bold" href="mailto:info@whiwh.com">{"(E) info@whiwh.com"}</a>
                </div>
                <div>
                    <span className="bold">{t("Hours of Operation")}</span>
                    <br />
                    <span className="bold">{t("Monday, Thursday and Friday ~ 9 am - 5 pm")}</span>
                    <br />
                    <span className="bold">{t("Tuesdays and Wednesdays ~ 9 am - 8 pm")}</span>
                    <br />
                    <span className="bold">{t("Every 3rd Saturday ~ 10 am - 4 pm")}</span>
                </div>
            </div>
        </div>
    )
}

export default WhiwhContact;