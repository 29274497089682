import React from "react";
import { useTranslation } from "react-i18next";
import advocasyImg from "../../assets/images/about/advocacy timeline1.png";
import approachImg from "../../assets/images/about/WHIWH approach diagram.png";
import logo from "../../assets/images/logos/Flourish_final.png";
import WhiwhContact from "../snippets.components/whiwhContant.component";
import whiwhApproach from "../../assets/images/about/whiwh's approach.PNG";
import backgroundImg from "../../assets/images/Re-design assets/Copy of Main background design.png";
import "./coverYourTrack.css";

function CoverYourTrack(params) {
    const { t, i18n } = useTranslation();

    return (
        <div className="img-wrap">
            <img src={backgroundImg} alt="background flourish access website" className="flourish-background-img" />
            <div className='About-Container-Cover-Your-Track parent-container-x-margins'>
                <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                    <span className="font-size-28-px bold">{t("cover-your-track-page.header1")}</span>
                    <span className="font-size-20-px ">{t("cover-your-track-page.header2")}</span>
                    <br />
                    <span className="">{t("cover-your-track-page.body.block1.header")}</span>
                    <ol className="">
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block1.text1")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block1.text2")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block1.text3")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block1.text4")}</span>
                        </li>
                    </ol>
                    <span className="">{t("cover-your-track-page.body.block2.header")}</span>
                    <ol className="">
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block2.text1")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block2.text2")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block2.text3")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block2.text4")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block2.text5")}</span>
                        </li>
                    </ol>
                    <span className="">{t("cover-your-track-page.body.block3.header")}</span>
                    <ol className="">
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block3.text1")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block3.text2")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block3.text3")}</span>
                        </li>
                    </ol>

                    <span className="">{t("cover-your-track-page.body.block4.header")}</span>
                    <ol className="">
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block4.text1")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block4.text2")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block4.text3")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block4.text4")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block4.text5")}</span>
                        </li>
                        <li className="margin-x-1-rem">
                            <span className="">{t("cover-your-track-page.body.block4.text6")}</span>
                        </li>
                    </ol>
                </div>


            </div>
        </div>
    )
}
export default CoverYourTrack;