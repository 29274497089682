import React, { useState, useRef, useEffect, useContext } from "react";
import DisplayContent from "./displayMedia";
import axios from "axios";
import mysqlServices from "../../services/mysqlServices";
import { Link } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';

import { AdminContext } from "../../App";
import adminServices from "../../services/adminServices";
import Cookies from 'universal-cookie';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";



function AdminPage(props) {
    const cookies = new Cookies();
    const canLoad = useRef(true);
    const [display, setDisplay] = useState(false);

    const [file, setFile] = useState(null);
    const [fileToUpload, setFileToUpload] = useState(null);
    const [message, setMessage] = useState(null);
    const [fileName, setFileName] = useState("")
    const [fileExtension, setFileExtension] = useState("")

    const { setAdminLoggedIn } = props;

    // const [adminPin, setAdminPin] = useState(null)


    // const [loading, setLoading] = useState(false);

    const adminState = useContext(AdminContext)

    const [errorMessage, setErrorMessage] = useState(null)

    const [adminUsername, setAdminUsername] = useState("");
    const [adminPassword, setAdminPassword] = useState("");
    const [adminEmail, setAdminEmail] = useState("");

    const [adminLoginPending, setAdminLoginPending] = useState("false");
    const [pinCode, setPinCode] = useState("");

    const [spinnerOn, setSpinnerOn] = useState(false)
    const pendingTime = 120000;

    const [adminEmailLoggedIn, setAdminEmailLoggedIn] = useState("")

    const [survey, setSurvey] = useState(null);

    const [selectedView, setSelectedView] = useState("");

    const [screenToolAnswers, setScreenToolAnswers] = useState(null);

    const [selectedScreenToolId, setSelectedScreenToolId] = useState(null);


    const [questionsAndAnswers, setQuestionsAndAnswers] = useState([
        {
            "index": 1,
            "question": {
                "en": "Have you felt uncomfortable doing or saying things around your current partner, parent, child, family member or a particular person in your life?",
                "fr": "Vous êtes-vous senti mal à l'aise de faire ou de dire des choses avec votre partenaire actuel, vos parents, vos enfants, un membre de votre famille ou une personne en particulier dans votre vie?"
            },
            "selected": null,
        },
        {
            "index": 2,
            "question": {
                "en": "How often did you experience verbal abuse (i.e. insult/yell/shout/curse at you or talk down to you) by your partner, ex-partner, parent, child, family member or a particular person in your life?",
                "fr": "À quelle fréquence avez-vous été victime de violence verbale (c'est-à-dire insulter/crier/crier/insulter ou vous insulter) de la part de votre partenaire, ex-partenaire, parent, enfant, membre de votre famille ou d'une personne en particulier dans votre vie?"
            },
            "selected": null,
            "sub_question": {
                "en": "If yes, have they ever done it in the presence of others, in public or during a phone/video call?",
                "fr": "Si oui, l'ont-ils déjà fait en présence d'autres personnes, en public ou lors d'un appel téléphonique/vidéo?"
            },
            "sub_selected": null
        },
        {
            "index": 3,
            "question": {
                "en": "How often did you experience physical abuse (i.e. punching, kicking, pressured/unwanted sexual acts) by your partner, an ex-partner, parent, child, family member or a particular person in your life?",
                "fr": "À quelle fréquence avez-vous été victime de violence physique (c'est-à-dire coups de poing, coups de pied, actes sexuels sous pression/non désirés) de la part de votre partenaire, d'un ex-partenaire, d'un parent, d'un enfant, d'un membre de votre famille ou d'une personne en particulier dans votre vie?"
            },
            "selected": null,
            "sub_question": {
                "en": "If yes, have they ever done it in the presence of others or in public?",
                "fr": "Si oui, l’ont-ils déjà fait en présence d’autres personnes ou en public?"
            },
            "sub_selected": null
        },
        {
            "index": 4,
            "question": {
                "en": "How often did your partner, an ex-partner, parent, child, family member or a particular person in your life threaten to harm you/someone you care about or make you feel afraid/scared?",
                "fr": "À quelle fréquence votre partenaire, un ex-partenaire, un parent, un enfant, un membre de votre famille ou une personne en particulier dans votre vie a-t-il menacé de vous faire du mal ou de faire du mal à quelqu'un qui vous est cher ou de vous faire peur?"
            },
            "selected": null,
        },
        {
            "index": 5,
            "question": {
                "en": "How often did your partner, an ex-partner, parent, child, family member or a particular person in your life control who you see, where you go, what you do, or what you wear?",
                "fr": "À quelle fréquence votre partenaire, un ex-partenaire, un parent, un enfant, un membre de votre famille ou une personne en particulier dans votre vie contrôlait-il qui vous voyez, où vous allez, ce que vous faites ou ce que vous portez?"
            },
            "selected": null,
        }
    ]);

    const options = [
        { "value": 0, "label": { "en": "Never", "fr": "Jamais" } },
        { "value": 1, "label": { "en": "One time", "fr": "Une fois" } },
        { "value": 2, "label": { "en": "Once a month", "fr": "Une fois par mois" } },
        { "value": 3, "label": { "en": "Every week", "fr": "Toutes les semaines" } },
        { "value": 4, "label": { "en": "Every day", "fr": "Tous les jours" } },
    ]

    const getFrequency = (question_index, option_index, isSub)=> {
        if(!selectedScreenToolId){
            var freq = 0;
            for (let [k, v] of Object.entries(screenToolAnswers)){
                let question = v?.filter(item => (item.question_index === question_index) && ((isSub && item.is_sub_answer === 1) || (!isSub && item.is_sub_answer === 0)))
                if (question.length) {
                    let answerObj = question[0];
                    if (answerObj?.selection === option_index) {
                        freq += 1;
                    } 

                }
            }
            return freq;

        }else{
            let question = screenToolAnswers[selectedScreenToolId].filter(item => (item.question_index === question_index) && ((isSub && item.is_sub_answer === 1) || (!isSub && item.is_sub_answer === 0)))
            if(question.length){
                let answerObj = question[0];
                if(answerObj?.selection === option_index){
                    return 1
                }else{
                    return 0 
                }

            }
        }
    }


    useEffect(() => {
        console.log("new state", adminState)
    }, [adminState])


    useEffect(() => {
        if (canLoad.current) {
            canLoad.current = false;
            // let ds = document.getElementById("gg");
            // ds.src = "/assets/uploadedMedia/images/cs.png"
            // console.log("ds", ds)
            // mysqlServices.getImages().then(data => {
            //     console.log(data);
            //     this.setState({ projects: data.projects, paragraphs: data.paragraphs });
            // }).catch(err => {

            // });

            let cookie = cookies.get("admin-user-cookie");
            if (cookie) {
                let [email, adminCookie] = cookie.split("%");
                // console.log(email, adminCookie)
                setAdminEmailLoggedIn(email);
                setAdminLoggedIn("true");
            }

            mysqlServices.getSurveyQuestions().then(data => {
                let allQuestions = data.data;
                let survey = {};
                for (let [key, value] of Object.entries(allQuestions)) {
                    if (survey[`${value.question}%${value.answer}`]) {
                        survey[`${value.question}%${value.answer}`] += 1;
                    } else {
                        survey[`${value.question}%${value.answer}`] = 1;
                    }
                }
                let surveyResult = [];
                // console.log(survey)
                for (let [key, value] of Object.entries(survey)) {
                    let question = key.split("%")[0]
                    let answer = key.split("%")[1]
                    let item = { question: question, answer: answer, number: value }
                    surveyResult.push(item);
                }

                let finalResults = {};
                for (let item of surveyResult) {
                    if (finalResults[item.question]) {
                        finalResults[item.question].push({ "answer": item.answer, "number": item.number })
                    } else {
                        finalResults[item.question] = [{ "answer": item.answer, "number": item.number }]
                    }

                }

                let finalResults2 = [];
                for (let [key, value] of Object.entries(finalResults)) {
                    let item = { question: key, answers: value }
                    finalResults2.push(item);
                }


                // console.log(finalResults2)
                setSurvey(finalResults2);

            }).catch(err => {
                console.log(err);
            })

            mysqlServices.getScreenToolAnswers().then(data => {
                let allQuestions = data.data;
                var screenToolGrouped = {};
                for(let item of allQuestions){
                    if(screenToolGrouped[item?.survey_id]){
                        screenToolGrouped[item?.survey_id] = [...screenToolGrouped[item?.survey_id], item]
                    }else{
                        screenToolGrouped[item?.survey_id] = [item]
                    }
                }
                setScreenToolAnswers(screenToolGrouped);
            }).catch(err => {
                console.log(err);
            })

        } 

    }, [])


    const handleFileChange = (e) => {
        setMessage(null);
        let imgSrc = e.target.files[0]
        let fullName = imgSrc.name;
        let name = fullName.split(".")[0]
        let extension = fullName.split(".")[1]
        setFileName(name);
        setFileExtension(extension)

        var reader = new FileReader();
        // let mg = document.getElementById("myimg");
        // mg.tilte = imgSrc.name;
        console.log(imgSrc)
        reader.onload = function (e) {
            let sr = e.target.result
            setFile(sr)

            var newImage = document.createElement('img');
            newImage.src = sr;

            document.getElementById("imgTest").innerHTML = newImage.outerHTML;
            // alert("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
            console.log("Converted Base64 version is " + document.getElementById("imgTest").innerHTML);
            let inn = document.getElementById("imgTest").innerHTML;

            // we need to take only the base64 code here
            let v = inn.split(",")[1]
            v = v.substring(0, v.length - 2)
            // console.log(v)
            // console.log("____")
            // console.log(v.substring(0, v.length - 2))
            setFileToUpload(v)

        }

        //we will check the file type here 
        reader.readAsDataURL(imgSrc);

    }

    const handleSubmitFile = () => {
        let randomFileExtension = Math.random().toString();
        console.log(randomFileExtension)
        // we need to get a unique name here for our file from the database
        if (!fileToUpload || !fileExtension || fileExtension === "" || !fileName || fileName === "") {
            return;
        }
        mysqlServices.uploadMedia({ "file": fileToUpload, "extension": fileExtension, "fileName": fileName + "." + randomFileExtension }).then(data => {
            setMessage("image uploaded")
        }).catch(err => {
            console.log(err);
        })
        setFile(null)
        setFileExtension("")
        setFileName("")
        setFileToUpload(null)

    }

    useEffect(() => {
        setDisplay(true);

    }, [file])

    const handleFileName = (e) => {
        setFileName(e.target.value)
    }
    const handleFileExtension = (e) => {
        setFileExtension(e.target.value)
    }

    const handleAdminLogin = (value) => {
        if (value === "false") {
            // localStorage.removeItem("admin-user");
            cookies.remove("admin-user-cookie")
            setAdminLoggedIn("false");
        } else if (value === "true") {
            // localStorage.setItem("admin-user", "true")
            // adminServices.setCookie().then(data => {
            //     console.log(data)
            // })

            setSpinnerOn(true);
            adminServices.adminLogin(adminUsername, adminPassword, adminEmail).then(data => {
                console.log(data)
                setSpinnerOn(false)
                if (data === "valid") {
                    setAdminLoginPending("true");
                    setTimeout(() => {
                        setAdminLoginPending("false")
                    }, pendingTime)
                }
            }).catch(err => {
                console.log(err);
                setSpinnerOn(false);
                setErrorMessage("Invalid Credentials")
            })


        }

    }

    // const addPin = () => {
    //     console.log("updating pin")
    //     let objectWihoutId = { ...adminPin }
    //     if (Object.keys(objectWihoutId) < 1) {
    //         console.log("no entry")
    //         setErrorMessage("no entry")
    //         return;
    //     }
    //     if (objectWihoutId.lng && !validCoordinate("lng", objectWihoutId.lng)) {
    //         console.log("invalide lng")
    //         setErrorMessage("invalide lng val <= 180.0 && val >= -180.0")
    //         return;
    //     }
    //     if (objectWihoutId.lat && !validCoordinate("lat", objectWihoutId.lat)) {
    //         console.log("invalide lat")
    //         setErrorMessage("invalide lat val <= 90.0 && val >= -90.0")
    //         return;
    //     }
    //     setLoading(true);
    //     mysqlServices.addPin(objectWihoutId).then((data) => {
    //         console.log(data);
    //         setLoading(false);
    //     }).catch(err => {
    //         alert(err)
    //         setLoading(false);
    //     })
    // }


    // const handleChange = (target, value) => {
    //     console.log(target, value)
    //     let newPin = { ...adminPin };
    //     newPin[target] = value;
    //     setAdminPin(newPin);
    // }

    // const getC = () => {
    //     adminServices.getCookie()
    // }

    //Latitude : max/min 90.0000000 to -90.0000000
    //Longitude: max / min 180.0000000 to - 180.0000000
    // const validCoordinate = (t, val) => {
    //     if (t === "lng") {
    //         if (val <= 180.0 && val >= -180.0) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     }
    //     else if (t === "lat") {
    //         if (val <= 90.0 && val >= -90.0) {
    //             return true;
    //         } else {
    //             return false;
    //         }
    //     }
    // }

    const handleClose = () => {
        setErrorMessage(null);
    }

    const handleAdminAuthInput = (name, value) => {
        switch (name) {
            case "username":
                setAdminUsername(value);
                break;
            case "password":
                setAdminPassword(value);
                break;
            case "email":
                setAdminEmail(value);
                break;
            case "pinCode":
                setPinCode(value);
                break;
            default:
                return;
        }
    }

    const verifyAdmin = () => {
        setSpinnerOn(true)
        adminServices.verifyAdmin(pinCode, adminUsername, adminEmail).then(cookie => {
            if (cookie) {
                setAdminLoggedIn("true");
                // this will make the cookie expire after 1 day
                cookies.set('admin-user-cookie', adminEmail + "%" + cookie, { path: '/', expires: new Date(Date.now() + 86400000) });
            } else {
                setErrorMessage("Error validating admin user")

            }
            setAdminLoginPending("false");
            setAdminUsername("");
            setAdminPassword("");
            setAdminEmail("")
            setSpinnerOn(false)
        }).catch(err => {
            console.log(err)
            setErrorMessage("Error validating admin user")
            setAdminLoginPending("false");
            setAdminUsername("");
            setAdminPassword("");
            setAdminEmail("")
            setSpinnerOn(false)
        })
    }

    return (
        <div className="">

            <Modal show={errorMessage !== null} onHide={handleClose} animation={false}>
                <Modal.Header closeButton>
                    <Modal.Title>System Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>{errorMessage + " !"}</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="margin-2-rem">
                {adminState === "false" ?
                    <div>
                        <input type="text" placeholder="Admin username" onChange={(e) => { handleAdminAuthInput("username", e.target.value) }} value={adminUsername} />
                        <input type="text" placeholder="Admin password" onChange={(e) => { handleAdminAuthInput("password", e.target.value) }} value={adminPassword} />
                        <input type="text" placeholder="Admin email" onChange={(e) => { handleAdminAuthInput("email", e.target.value) }} value={adminEmail} />
                        <button onClick={() => { handleAdminLogin("true") }} >Admin Login</button>
                    </div> :
                    <button onClick={() => { handleAdminLogin("false") }} >Admin Logout</button>
                }
                {spinnerOn ? <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner> : null}

                {adminLoginPending === "true" ?
                    <div>
                        <input type="text" placeholder="pin code" onChange={(e) => { handleAdminAuthInput("pinCode", e.target.value) }} value={pinCode} />
                        <button onClick={() => { verifyAdmin() }} >Verify</button>
                    </div>
                    : null
                }
                <p className={`${adminState === "true" ? "background-green" : "background-red"} margin-1-rem width-fit padding-1-rem`}>{adminState === "true" ? `Admin is logged in as ${adminEmailLoggedIn}` : "Admin is not logged in"}</p>
                <hr />
            </div>

            {adminState === "true" ?

                <div>


                    <div className="margin-2-rem">

                        <div id="imgTest" className="invisible" ></div>
                        <input type="text" placeholder="file name" value={fileName} onChange={(e) => { handleFileName(e) }} />
                        <input type="text" placeholder="file extension" value={fileExtension} onChange={(e) => { handleFileExtension(e) }} />
                        <input type="file" onChange={(e) => { handleFileChange(e) }} />
                        <button onClick={() => { handleSubmitFile() }} >Upload File</button>
                        {display ?
                            <DisplayContent src={file} del={null} />
                            : null
                        }
                        {message ? <p className="font-size-20-px">{message}</p> : null}
                        <p className="padding-1-rem background-color-light-pink width-fit margin-1-rem">
                            <Link to={"/images-page"} >Check Images Page</Link>
                        </p>

                        <hr />

                    </div>


                    <div className="display-flex flex-wrap column-gap-1-rem row-gap-1-rem margin-2-rem">
                        <span className={`cursor-pointer width-fit padding-1-rem border-radius-5-px ${selectedView === "toolkitSurvey" ? "background-color-light-orange" : "background-color-light-pink"}`} onClick={() => setSelectedView("toolkitSurvey")}>View Toolkit Survey Answers</span>
                        <span className={`cursor-pointer width-fit padding-1-rem border-radius-5-px ${selectedView === "screenTool" ? "background-color-light-orange" : "background-color-light-pink"}`} onClick={() => setSelectedView("screenTool")}>View GBV Screening Tool Answers</span>
                    </div>


                    <div className=''>

                        {/* <div className='Admin-Pin-Admin-page'>

                            <div className="display-flex">
                                <input type="number" placeholder="lng" value={adminPin?.lng ?? ""} onChange={(e) => { handleChange("lng", e.currentTarget.value) }} />
                                <input type="number" placeholder="lat" value={adminPin?.lat ?? ""} onChange={(e) => { handleChange("lat", e.currentTarget.value) }} />
                            </div>

                            <input type="text" placeholder="name" value={adminPin?.name ?? ""} onChange={(e) => { handleChange("name", e.target.value) }} />

                            <div className="display-flex">
                                <input type="text" placeholder="street number" value={adminPin?.street_number ?? ""} onChange={(e) => { handleChange("street_number", e.target.value) }} />
                                <input type="text" placeholder="street name" value={adminPin?.street_name ?? ""} onChange={(e) => { handleChange("street_name", e.currentTarget.value) }} />
                                <input type="text" placeholder="suite" value={adminPin?.suite ?? ""} onChange={(e) => { handleChange("suite", e.currentTarget.value) }} />
                                <input type="text" placeholder="city" value={adminPin?.city ?? ""} onChange={(e) => { handleChange("city", e.currentTarget.value) }} />
                                <input type="text" placeholder="province" value={adminPin?.province ?? ""} onChange={(e) => { handleChange("province", e.currentTarget.value) }} />
                                <input type="text" placeholder="postal code" value={adminPin?.postal_code ?? ""} onChange={(e) => { handleChange("postal_code", e.currentTarget.value) }} />
                            </div>

                            <input type="text" placeholder="webiste" value={adminPin?.website ?? ""} onChange={(e) => { handleChange("website", e.currentTarget.value) }} />
                            <input type="text" placeholder="phone" value={adminPin?.phone ?? ""} onChange={(e) => { handleChange("phone", e.currentTarget.value) }} />
                            <input type="text" placeholder="email" value={adminPin?.email ?? ""} onChange={(e) => { handleChange("email", e.currentTarget.value) }} />
                            <input type="text" placeholder="remarks" value={adminPin?.remarks ?? ""} onChange={(e) => { handleChange("remarks", e.currentTarget.value) }} />
                            <input type="text" placeholder="filters" value={adminPin?.filters ?? ""} onChange={(e) => { handleChange("filters", e.currentTarget.value) }} />
                            <input type="text" placeholder="description" value={adminPin?.description ?? ""} onChange={(e) => { handleChange("description", e.currentTarget.value) }} />


                            <div className='display-flex margin-1-rem'>
                                <button onClick={() => { addPin() }} >Add Pin</button>
                                {loading ?
                                    <Spinner animation="border" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </Spinner> : null}
                            </div>

                            
                         

                        </div> */}


                        {(selectedView === "toolkitSurvey") ?

                            survey !== null && survey?.length ?
                                <div className="background-color-light-pink margin-1-rem padding-1-rem">
                                    <h3>Survey Results</h3>
                                    {survey.map(item =>
                                        <div className="">
                                            <p>{item.question}</p>
                                            {item.answers.map(item2 =>
                                                <div className="display-flex column-gap-1-rem">
                                                    <p>{item2.answer}</p>
                                                    <p>{item2.number}</p>
                                                </div>

                                            )}

                                            <hr />
                                        </div>
                                    )}
                                </div> : <div className="background-color-light-pink margin-1-rem padding-1-rem">No Toolkit Survey Data Yet</div>
                            : null
                        }

                        {(selectedView === "screenTool") ?

                            screenToolAnswers !== null && Object.keys(screenToolAnswers).length ?

                                <div className="background-color-light-pink margin-1-rem padding-1-rem">
                                    <h3>Screen Tool Results</h3>
                                    <p>{`Number of screen tool use: ${Object.keys(screenToolAnswers).length}`}</p>
                                    <span className={`cursor-pointer width-fit padding-0-5-rem border-radius-5-px ${!selectedScreenToolId ? "background-color-light-orange" : "background-color-light-grey"}`} onClick={() => setSelectedScreenToolId(null)}>Click to Show Screen Tool Statistics</span>
                                    <hr />
                                    <div className="display-flex flex-wrap column-gap-2-rem row-gap-2-rem">
                                        <div className="">
                                            <p>Select Screen Tool Entry</p>
                                            <div className="display-flex flex-direction-column column-gap-1-rem row-gap-1-rem height-20-rem overflow-y-auto background-color-light-grey padding-1-rem">
                                                {Object.keys(screenToolAnswers).map((item, index)=> 
                                                    <span onClick={() => setSelectedScreenToolId(item)} className={`cursor-pointer padding-0-5-rem ${selectedScreenToolId === item ? "background-color-light-orange" : "background-color-light-pink"}`}>{`${index + 1}. Entry Score: ${screenToolAnswers[item][0].survey_score} `}</span>)}
                                            </div>
                                        </div>
                                        {/* {screenToolAnswers.map(item =>
                                            <div className="">
                                                <p>{item.question}</p>
                                                {item.answers.map(item2 =>
                                                    <div className="display-flex column-gap-1-rem">
                                                        <p>{item2.answer}</p>
                                                        <p>{item2.number}</p>
                                                    </div>

                                                )}

                                                <hr />
                                            </div>
                                        )} */}

                                        <div className='Questions-Container-Admin'>
                                            {questionsAndAnswers.map(item =>
                                                <div className='display-flex flex-direction-column column-gap-1-rem row-gap-1-rem'>
                                                    <div className='display-flex column-gap-0-5-rem row-gap-0-5-rem'>
                                                        <span className='bold'>{item.index}.</span>
                                                        <span className=''>{item.question.en}</span>
                                                    </div>
                                                    <div className={`display-flex flex-wrap justify-content-center align-items-center column-gap-2-rem row-gap-2-rem`}>
                                                        {options.map(opt =>
                                                            <span className={`GBV-Option-Button ${(getFrequency(item.index, opt.value, false) !== 0) ? "background-color-dark-pink color-white" : "background-color-dark-orange"}`}>
                                                                {`${opt.label.en}: ${getFrequency(item.index, opt.value, false)}`}
                                                            </span>
                                                        )}
                                                    </div>
                                                    {item.sub_question ?
                                                        <div className='margin-left-3-rem display-flex flex-direction-column column-gap-1-rem row-gap-1-rem'>
                                                            <div className='display-flex column-gap-0-5-rem row-gap-0-5-rem'>
                                                                <span className='bold'>a.</span>
                                                                <span className=''>{item.sub_question.en}</span>
                                                            </div>
                                                            <div className={`display-flex flex-wrap justify-content-center align-items-center column-gap-2-rem row-gap-2-rem`}>
                                                                {options.map(opt =>
                                                                    <span className={`GBV-Option-Button ${(getFrequency(item.index, opt.value, true) !== 0) ? "background-color-dark-pink color-white" : "background-color-dark-orange"}`}>
                                                                        {`${opt.label.en}: ${getFrequency(item.index, opt.value, true)}`}
                                                                    </span>
                                                                )}
                                                            </div>

                                                        </div>
                                                        : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                       
                                </div> : <div className="background-color-light-pink margin-1-rem padding-1-rem">No Screen Tool Data Yet</div>

                            : null
                        }




                    </div>
                </div> : null
            }



        </div>
    )
}
export default AdminPage;