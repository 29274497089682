import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from 'react-i18next';

import { ReactDOM } from "react";
import "./gbvPodcast.css";
import mysqlServices from "../../../services/mysqlServices";

export default function GbvPodcast(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();

    const [podcasts, setPodcasts] = useState(null);


    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getGBVPodcasts().then(data => {
                let podcasts = data.data;
                if (!podcasts) { return }
                //console.log(podcasts)
                setPodcasts(podcasts)
            }).catch(err => {
                console.log(err);
            })
        } else {

        }
    }, [])


    return (
        <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-1-rem column-gap-1-rem">
            <span className='font-size-22-px bold'>{t("Safe Spaces Podcast")}</span>
            <span className=''>{t("Safe Spaces podcast aims to raise awareness about gender-based violence through honest conversations, sharing real-life stories, offering support resources, and educating individuals on prevention strategies. Our goal is to establish a platform for survivors to share their experiences, be comfortable having uncomfortable conversations and inspire positive change in addressing this pervasive issue. We strive to facilitate open and honest conversations that foster understanding and empathy and contribute to a culture of respect, consent, and gender equality. Let’s unite in the battle against Gender-based Violence and become a part of the solution.")}</span>
            <div className="display-flex flex-direction-column column-gap-2-rem row-gap-2-rem">
                {/* <span className="color-grey">
                    {"Coming Soon ..."}
                </span> */}
                {/* {renderMarkdown(mInput, "george")} */}

                {podcasts != null && podcasts.length > 0 ? podcasts.map(podcast =>
                    <div>
                        <div>{i18n.language === "fr" ? podcast.title_french : podcast.title_english}</div>
                        <div className="Podcast-Player"><iframe className="Podcast-Iframe" frameborder="no" scrolling="no" seamless src={podcast.src}></iframe></div>
                        <span>{i18n.language === "fr" ? podcast.summary_french : podcast.summary_english}</span>
                    </div>
         
                ) : null}
          

           
            </div>
        </div>
    )
}
