import React, { useState, useRef, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import mysqlServices from "../../../services/mysqlServices";
import { useTranslation } from "react-i18next";
import Collapse from 'react-bootstrap/Collapse';
import { BiDownArrowAlt, BiRightArrowAlt } from "react-icons/bi"

function Other(params) {
    const canLoad = useRef(true);
    const [input, setInput] = useState("")
    const [links, setLinks] = useState([]);
    const { t, i18n } = useTranslation();
    const [toOpen, setToOpen] = useState("");
    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getResourcesLinks().then(data => {
                let lin = data.data;
                let allLinks = [];
                for(let item of lin){
                    let allItems = item.links.split("{&}");
                    let linksItems = []
                    for (let itm of allItems) {
                        let [english, french, link, type] = itm.split("{%}");
                        linksItems.push({ "english": english, "french": french, "link": link, "type": type })
                    }
                    allLinks.push({ "header_english": item.header_english, "header_french": item.header_french, "links": linksItems })
                }
  
                setLinks(allLinks);
            }).catch(err => {
                console.log(err);
            })
        } else {
          
        }
    }, [])

    return (
        <div className="display-flex flex-direction-column column-gap-1-rem row-gap-1-rem" >
            <span className="font-size-22-px bold">{t("additional-information")}</span>
            {links.length > 0 ? 
            links.map(item => 
                <div className="display-flex flex-direction-column">
                    <span className="display-flex">
                        <span>{toOpen !== item.header_english ? <BiRightArrowAlt /> : <BiDownArrowAlt />}</span>
                        <span className="font-size-18-px bold cursor-pointer hover-background-color-light-pink width-fit padding-2-px border-radius-5-px" onClick={() => { toOpen !== item.header_english ? setToOpen(item.header_english) : setToOpen("") }}>{i18n.language === "fr" ? item.header_french : item.header_english}</span>
                    </span>

                    <Collapse in={toOpen===item.header_english}>
                        <div className="display-flex flex-direction-column">
                            {item.links.length > 0 ?
                                item.links.map(linkItem =>
                                    <span>
                                        {linkItem.type === "link" ?
                                            <a className="font-blue" href={linkItem.link} target="_blank" rel="noreferrer" >
                                                {i18n.language === "fr" ? linkItem.french : linkItem.english}
                                            </a>
                                            :
                                            <span className="cursor-pointer font-blue underline" onClick={() => { window.open(linkItem.link) }}>
                                                {i18n.language === "fr" ? linkItem.french : linkItem.english}
                                            </span>
                                        }
                                    </span>
                                )
                                : null}
                        </div>
                    </Collapse>
                    
                </div>
                ) : null}
        </div>
    )
}
export default Other;