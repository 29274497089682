import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

function PrivacyPolicy({ showModal, setShowModal }) {
    const handleClose = () => setShowModal(false);


    return (

        <Modal show={showModal} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Privacy Policy</Modal.Title>
            </Modal.Header>

            <Modal.Body>






                <div>
              
                    <span className="bold font-size-18-px">flourishaccess.ca Privacy Policy</span>
                    <br />
                    <br />
                    <p>flourishaccess.ca (the "Site") is owned and operated by WHIWH. WHIWH can be contacted at: <br />flourish@whiwh.com<br />(416) 593-7655<br />2 Carlton Street, Suite 500, Toronto, Ontario, M5B 1J3
                    </p>
                    <p><span className="bold">Purpose</span><br />The purpose of this privacy policy (this "Privacy Policy") is to inform users of our Site of the following:
                    </p>
                    <ol start="1">
                        <li value="1"><span>The personal data we will collect;</span><span ><br /></span>
                        </li>
                        <li value="2"><span>Use of collected data;</span><span ><br /></span>
                        </li>
                        <li value="3"><span>Who has access to the data collected;</span><span ><br /></span>
                        </li>
                        <li value="4"><span>The rights of Site users; and</span><span ><br /></span>
                        </li>
                        <li value="5"><span>The Site's cookie policy.</span><span><br /></span>
                        </li>
                    </ol>
                    <p>This Privacy Policy applies in addition to the terms and conditions of our Site.
                    </p><div>
                        <p><span className="bold">Consent</span><br />By using our Site users agree that they consent to:
                        </p>
                        <ol start="1">
                            <li value="1"><span>The conditions set out in this Privacy Policy; and</span><span ><br /></span>
                            </li>
                            <li value="2"><span>The collection, use, and retention of the data listed in this Privacy Policy.</span><span ><br /></span>
                            </li>
                        </ol></div>
                    <p><span className="bold">Personal Data We Collect</span><br />We only collect data that helps us achieve the purpose set out in this Privacy Policy. We will not collect any additional data beyond the data listed below without notifying you first.<br /><br />
                    </p>
                    <p><span className="bold">Data Collected Automatically</span><br />When you visit and use our Site, we may automatically collect and store the following information:
                    </p>
                    <ol start="1">
                        <li value="1"><span>Content viewed.</span><span><br /></span>
                        </li>
                    </ol>
                    <p><span className="bold">Data Collected in a Non-Automatic Way</span><br />We may also collect the following data when you perform certain functions on our Site:
                    </p>
                    <ol start="1" >
                        <li value="1"><span>First and last name; and</span><span><br /></span>
                        </li>
                        <li value="2"><span>Email address.</span><span ><br /></span>
                        </li>
                    </ol>
                    <p >This data may be collected using the following methods:
                    </p>
                    <ol start="1" >
                        <li value="1"><span>Web form.</span><span><br /></span>
                        </li>
                    </ol>
                    <p ><span className="bold">How We Use Personal Data</span><br />Data collected on our Site will only be used for the purposes specified in this Privacy Policy or indicated on the relevant pages of our Site. We will not use your data beyond what we disclose in this Privacy Policy.<br /><br />The data we collect automatically is used for the following purposes:
                    </p>
                    <ol start="1" >
                        <li value="1"><span>Google Analytics.</span><span ><br /></span>
                        </li>
                    </ol>
                    <p >The data we collect when the user performs certain functions may be used for the following purposes:
                    </p>
                    <ol start="1" >
                        <li value="1"><span>Communication.</span><span><br /></span>
                        </li>
                    </ol>

                    <p ><span className="bold">Who We Share Personal Data With</span><br /><span>Employees</span><br />We may disclose user data to any member of our organization who reasonably needs access to user data to achieve the purposes set out in this Privacy Policy.
                    </p><div>
                        <p><span className="bold">Other Disclosures</span><br />We will not sell or share your data with other third parties, except in the following cases:
                        </p>
                        <ol start="1">
                            <li value="1"><span>If the law requires it;</span><span><br /></span>
                            </li>
                            <li value="2"><span>If it is required for any legal proceeding;</span><span><br /></span>
                            </li>
                            <li value="3"><span>To prove or protect our legal rights; and</span><span><br /></span>
                            </li>
                            <li value="4"><span>To buyers or potential buyers of this company in the event that we seek to sell the company.</span><span><br /></span>
                            </li>
                        </ol>
                        <p>If you follow hyperlinks from our Site to another Site, please note that we are not responsible for and have no control over their privacy policies and practices.
                        </p></div>
                    <p ><span className="bold">How Long We Store Personal Data</span><br />User data will be stored until the purpose the data was collected for has been achieved.<br /><br />You will be notified if your data is kept for longer than this period.
                    </p>
                    <p><span className="bold">How We Protect Your Personal Data</span><br />We use the strongest available browser encryption and store all of our data on servers in secure facilities. All data is only accessible to our employees. Our employees are bound by strict confidentiality agreements and a breach of this agreement would result in the employee's termination<br /><br />While we take all reasonable precautions to ensure that user data is secure and that users are protected, there always remains the risk of harm. The Internet as a whole can be insecure at times and therefore we are unable to guarantee the security of user data beyond what is reasonably practical.
                    </p><div>
                        <p><span className="bold">Children</span><br />We do not knowingly collect or use personal data from children under 13 years of age. If we learn that we have collected personal data from a child under 13 years of age, the personal data will be deleted as soon as possible. If a child under 13 years of age has provided us with personal data their parent or guardian may contact us.
                        </p></div>
                    <p ><span className="bold">How to Access, Modify, Delete, or Challenge the Data Collected</span><br />If you would like to know if we have collected your personal data, how we have used your personal data, if we have disclosed your personal data and to who we disclosed your personal data, or if you would like your data to be deleted or modified in any way, please contact us.
                    </p><div>
                        <p><span className="bold">Cookie Policy</span><br />A cookie is a small file, stored on a user's hard drive by a website. Its purpose is to collect data relating to the user's browsing habits. You can choose to be notified each time a cookie is transmitted. You can also choose to disable cookies entirely in your internet browser, but this may decrease the quality of your user experience.
                        </p>
                        <p >We use the following types of cookies on our Site:
                        </p>
                        <ol start="1" >
                            <li value="1"><span>Functional cookies</span><br />Functional cookies are used to remember the selections you make on our Site so that your selections are saved for your next visits; and<span ><br /></span>
                            </li>
                            <li value="2"><span>Third-Party Cookies</span><br />Third-party cookies are created by a website other than ours. We may use third-party cookies to achieve the following purposes:<span><br /></span>
                                <ol start="1" >
                                    <li value="1"><span>Saving the user selected langugae.</span><span ><br /></span>
                                    </li>
                                </ol>
                            </li>
                        </ol></div>
                    <p><span className="bold">Modifications</span><br />This Privacy Policy may be amended from time to time in order to maintain compliance with the law and to reflect any changes to our data collection process. When we amend this Privacy Policy we will update the "Effective Date" at the top of this Privacy Policy. We recommend that our users periodically review our Privacy Policy to ensure that they are notified of any updates. If necessary, we may notify users by email of changes to this Privacy Policy.
                    </p>
                    <p ><span className="bold">Contact Information</span><br />If you have any questions, concerns or complaints, you can contact us at: <br />flourish@whiwh.com<br />(416) 593-7655<br />2 Carlton Street, Suite 500, Toronto, Ontario, M5B 1J3
                    </p></div>






            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>Close</Button>
            </Modal.Footer>
        </Modal>







    );
}

export default PrivacyPolicy;