import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "../../tools.component/youtubeEmbed.component";
import mysqlServices from "../../../services/mysqlServices";
function Videos(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();
    const [videos, setVideos] = useState(null);
    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getResourcesVideos().then(data => {
                //console.log(data.data)
                setVideos(data.data);
            }).catch(err => {
                console.log(err);
            })
        } else {

        }
    }, [])

    return (
        <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-1-rem column-gap-1-rem">
        
            {/* <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-0-5-rem column-gap-0-5-rem">
                <span className="bold font-size-22-px">{t("resources-page.videos.header1")}</span>
                <YoutubeEmbed embedId="rOnazC7hxbE" title="Flourish FGM wsubs youtube video" videoStyle="video-responsive" />
                <div className="display-flex flex-direction-row row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                   
                    <div className="">
                        <span className="bold font-size-20-px">{"Flourish 1.0 "}</span>
                        {t("resources-page.videos.text1")}

                    </div>
                </div>
            </div> */}

            {(videos && videos.length > 0) ?  videos.map(item => 
                <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-0-5-rem column-gap-0-5-rem">
                    <span className="bold font-size-22-px">{i18n.language === "fr" ? item.header_french : item.header_english}</span>
                    <YoutubeEmbed embedId={item.src} title="Flourish youtube video" videoStyle="video-responsive" />
                    <div className="display-flex flex-direction-row row-gap-0-5-rem column-gap-0-5-rem flex-wrap">

                        <div className="">
                            {i18n.language === "fr" ? item.paragraph_french : item.paragraph_english}
                        </div>
                    </div>
                </div>
            ) : null}

{/* 
            <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-0-5-rem column-gap-0-5-rem">
                <span className="bold font-size-22-px">{t("resources-page.videos.header2")}</span>
            </div> */}
        </div>
    )
}
export default Videos;