import React, { useEffect, useState, useRef } from "react";

// import { HashLink as LinkTo } from "react-router-hash-link";

import Logo from "../../assets/images/logos/Flourish_final.png";
// import { FiFacebook, FiTwitter, FiInstagram, FiLayers } from "react-icons/fi";
// import { AiOutlineMail, AiOutlineHome } from "react-icons/ai";
import { TbMinusVertical } from "react-icons/tb";
// import { TbMap2 } from 'react-icons/tb'
// import { BsChatDots } from "react-icons/bs";
import "./header.css";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cookies from 'js-cookie';
// import { TbUserExclamation } from "react-icons/tb";
// import { ImExit } from "react-icons/im";
// import Dropdown from 'react-bootstrap/Dropdown';
import Selection from "../tools.component/selection.component";
// import ReactGA from "react-ga4";

import { useLocation } from 'react-router-dom';
import GA4React from "ga-4-react";
// import { CgErase } from "react-icons/cg";


function Header(props) {
    const minimumWindowSize = 768;
    // const [stickyHeader, setStickyHeader] = useState(false);
    // const lastScrollPosition = useRef(0);

    const [windowSize, setWindowSize] = useState(window.innerWidth);
    const [language, setLanguage] = useState('en');
    // const navigate = useNavigate();
    let location = useLocation();
    const [selectedPage, setSelectedPage] = useState(window.location.href.split('/').slice(-1)[0]);

    const { t, i18n } = useTranslation();
    const currentLanguageCode = localStorage.getItem('i18nextLng') || cookies.get('i18next') || 'en';
    const ref = useRef(true);

    const resourcesSelectionOptions = [{ "name": "What is GBV?", "link": "/gbv-resources/gbv" }, { "name": "digital-stories", "link": "/gbv-resources/digital-stories" }, { "name": "blogs", "link": "/gbv-resources/blogs" }, { "name": "videos", "link": "/gbv-resources/videos" }, { "name": "gbv-podcast", "link": "/gbv-resources/gbv-podcast" }, { "name": "gbv-risk-assessment", "link": "/gbv-resources/gbv-risk-assessment" }, { "name": "additional-information", "link": "/gbv-resources/additional-information" }];
    const fgmResourcesSelectionOptions = [{ "name": "What is FGM/C?", "link": "/fgm-resources/fgm" }, { "name": "toolkit", "link": "/fgm-resources/toolkit" }, { "name": "digital-stories", "link": "/fgm-resources/digital-stories" }, { "name": "videos", "link": "/fgm-resources/videos" }, { "name": "podcast", "link": "/fgm-resources/podcast" }, { "name": "webinars", "link": "/fgm-resources/webinars" }, { "name": "additional-information", "link": "/fgm-resources/additional-information" }];
    const headerSelectionOptions = [{ "name": "home", "link": "/home" }, { "name": "about", "link": "/about" }, { "name": "map", "link": "/map" }, { "name": "fgm-resources", "link": "/fgm-resources/fgm" }, { "name": "gbv-resources", "link": "/gbv-resources/gbv" }, { "name": "contact", "link": "/contact" }, { "name": "cover-your-track", "link": "/cover-your-track" }, { "name": "quit", "link": "/google.ca" }];

    const [gbvResourcesOpen, setGbvResourcesOpen] = useState(false);
    const [fgmResourcesOpen, setFgmResourcesOpen] = useState(false);
    const [currentResourcesValue, setCurrentResourcesValue] = useState("");

    const [headerOpen, setHeaderOpen] = useState(false);
    const [headerCurrentValue, setHeaderCurrentValue] = useState("home");

    const ga = useRef("");
    const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE);

    // const handleScroll = (e) => {
    //     const winScroll =
    //         document.body.scrollTop || document.documentElement.scrollTop;
    //     if (winScroll <= lastScrollPosition.current || winScroll < 20) {
    //         setStickyHeader(true);

    //     } else {
    //         setStickyHeader(false);
    //     }

    //     lastScrollPosition.current = winScroll;
    // };
    const handleResize = () => {
        setWindowSize(window.innerWidth);
    }
    useEffect(() => {
        //window.addEventListener("scroll", handleScroll);
        if (ref.current) {
            // console.log(currentLanguageCode)
            // console.log(selectedPage)
            ref.current = false;
            setLanguage(currentLanguageCode);
            setGA()
            // setSelectedPage(window.location.href.split('/').slice(-1)[0]);
        }
        window.addEventListener("resize", handleResize);

        return () => {
            //    window.removeEventListener("scroll", handleScroll);
            window.removeEventListener("resize", handleResize);
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // console.log("==============================================")
        // console.log(">>>>>>",location.pathname)
        // ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
        if (ga.current !== "") {
            // console.log(ga.current);
            ga.current.gtag('event', 'pageview', window.location.pathname + window.location.search)
        }
    }, [location])

    const changeLanguage = (ln) => {
        if (language === ln) {
            return;
        }
        setLanguage(ln);
        i18n.changeLanguage(ln);
    }

    // function handleNavigate(page){
    //     navigate(`/${page}`)
    //     handleResourcesOpen(false);
    //     //setCurrentValue("")
    //     setSelectedPage(page.split("/")[0])
    // }

    function handleNavigateHeader(page) {
        handleGbvResourcesOpen(false);
        handleFgmResourcesOpen(false);
        //setCurrentValue("")
        setSelectedPage(page.split("/")[0])
        // setTimeout(()=>{
        //     // we need some time to allow the page to load
        //     console.log(window.location.pathname + window.location.search)
        //     ReactGA.pageview(window.location.pathname + window.location.search);
        // }, 20)

    }

    const handleGbvResourcesOpen = (state) => {
        setGbvResourcesOpen(state);
    };

    const handleFgmResourcesOpen = (state) => {
        setFgmResourcesOpen(state);
    };

    const handleHeaderOpen = (state) => {
        setHeaderOpen(state);
    };

    const handleFgmResourcesSelectionChange = (item) => {
        setSelectedPage('fgm-resources/fgm')
    }

    const handleGbvResourcesSelectionChange = (item) => {
        setSelectedPage('gbv-resources/gbv')
    }

    const handleHeaderSelectionChange = (item) => {
        // if(item==="resources"){
        //     navigate(`/${item}/"toolkit"`);
        // }
        // else 
        if (item === "quit") {
            handleQuit()
        }
        // } else if (item === "home") {
        //     navigate(`/`);
        // }
        // else{
        //     navigate(`/${item}`);
        // }
        setSelectedPage(item);
        setHeaderCurrentValue(item);
        setHeaderOpen(false);
    }
    const handleQuit = () => {
        // window.open("about:blank", "_self");
        window.open("https://www.google.ca/", "_self");
        window.close();
    }


    const setGA = async () => {
        //console.log("analytics", process.env.REACT_APP_GOOGLE_ANALYTICS_TRAKING_CODE, window.location.pathname + window.location.search)
        await ga4react.initialize().then((ga4) => {
            console.log(ga4)
            // ga4.pageview(window.location.pathname + window.location.search)
            ga4.gtag('event', 'pageview', window.location.pathname + window.location.search) // or your custom gtag event
            ga.current = ga4
        }).catch(err => {
            console.log(err);
        })

    }


    return (
        <div className={`Header`}>
           
            <div className="Header-Down">
                <div className="Header-Logo">
                    <Link to={"/"} onClick={() => { handleNavigateHeader("") }}>
                    <img
                        src={Logo}
                        alt="Flourish Access Logo"
                        className="Header-Logo-Image"
                        /></Link>
                </div>
                {windowSize > minimumWindowSize ?

                    <div className="Header-Navigation">
           
                        <Link to={"/home"} className="text-decoration-none">
                            <div className={`Header-Navigation-Item ${selectedPage === 'home' ? "Selected" : ""}`} alt="home button" key={"home button"} onClick={() => { handleNavigateHeader("home") }}>
                                {/* <div className={`Header-Navigation-Icon ${selectedPage === '' ? "Selected" : ""}`} key={"1 home button"}>
                                    <AiOutlineHome />
                                </div> */}
                                <span className="color-pink text-decoration-none " key={"2 home button"}>{t('home')}</span>
                            </div>
                        </Link>
                        <Link to={"/about"} className="text-decoration-none">
                            <div className={`Header-Navigation-Item ${selectedPage === 'about' ? "Selected" : ""}`} alt="about button" key={"about button"} onClick={() => { handleNavigateHeader("about") }}>
                                {/* <div className={`Header-Navigation-Icon ${selectedPage === 'about' ? "Selected" : ""}`} key={"1 about button"}>
                                    <TbUserExclamation />
                                </div> */}
                                <span className="color-pink text-decoration-none " key={"2 about button"}>{t('about')}</span>
                            </div>
                        </Link>
                        <Link to={"/map"} className="text-decoration-none">
                            <div className={`Header-Navigation-Item ${selectedPage === 'map' ? "Selected" : ""}`} alt="map button" key={"map button"} onClick={() => { handleNavigateHeader("map") }}>
                                {/* <div className={`Header-Navigation-Icon ${selectedPage === 'map' ? "Selected" : ""}`} key={"1map button"}>
                                    <TbMap2 />
                                </div> */}
                                <span className="color-pink text-decoration-none " key={"2map button"}>{t('map')}</span>
                            </div>
                        </Link>
                        <div className={`Header-Navigation-Item `} alt="fgm resources button" key={"resources button"} onMouseLeave={() => { handleFgmResourcesOpen(false) }} onMouseEnter={() => { handleFgmResourcesOpen(true) }}>
                            <Link to={"/fgm-resources/fgm"} className="text-decoration-none ">
                                <div className={`Header-Navigation-Item ${selectedPage === 'fgm-resources/fgm' ? "Selected" : ""}`} alt="fmg resources button" key={"resources button"} onClick={() => { handleNavigateHeader("fgm-resources/fgm") }}>

                                    <span className="width-3-rem height-1-7-rem" key={"2resources button"}>
                                        <Selection values={fgmResourcesSelectionOptions} label={t("fgm-resources")} open={fgmResourcesOpen} handleOpen={handleFgmResourcesOpen} setCurrentValue={setCurrentResourcesValue} currentValue={currentResourcesValue} onChange={handleFgmResourcesSelectionChange} itemStyle="Resources-Item-Style " />
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <div className={`Header-Navigation-Item `} alt="gbv resources button" key={"gbv resources button"} onMouseLeave={() => { handleGbvResourcesOpen(false) }} onMouseEnter={() => { handleGbvResourcesOpen(true) }}>
                            <Link to={"/gbv-resources/gbv"} className="text-decoration-none ">
                                <div className={`Header-Navigation-Item ${selectedPage === 'gbv-resources/gbv' ? "Selected" : ""}`} alt="resources button" key={"gbv resources button"} onClick={() => { handleNavigateHeader("gbv-resources/gbv") }}>

                                    <span className="width-3-rem margin-right-1-rem height-1-7-rem" key={"2resources button"}>
                                        <Selection values={resourcesSelectionOptions} label={t("gbv-resources")} open={gbvResourcesOpen} handleOpen={handleGbvResourcesOpen} setCurrentValue={setCurrentResourcesValue} currentValue={currentResourcesValue} onChange={handleGbvResourcesSelectionChange} itemStyle="Resources-Item-Style " />
                                    </span>
                                </div>
                            </Link>
                        </div>
                        <Link to={"/contact"} className="text-decoration-none" >
                            <div className={`Header-Navigation-Item ${selectedPage === 'contact' ? "Selected" : ""} `} alt="contact button" key={"contact button"} onClick={() => { handleNavigateHeader("contact") }}>

                                {/* <div className={`Header-Navigation-Icon ${selectedPage === 'contact' ? "Selected" : ""}`} key={"1contact button"}>
                                    <BsChatDots />
                                </div> */}
                                <span className="color-pink text-decoration-none " key={"2contact button"}>{t('contact')}</span>

                            </div>
                        </Link>
                        <div className={`Top-Header-Navigation-Item`} alt="quit button" onClick={() => { handleQuit() }}>
                            {/* <div className={`Small-Top-Header-Navigation-Icon`}>
                            <ImExit />
                        </div> */}
                            <span>{t('quick-exit')}</span>
                        </div>
                        <Link to={"/cover-your-track"} className="text-decoration-none" >
                            <div className={`Top-Header-Navigation-Item ${selectedPage === 'cover-your-track' ? "Selected" : ""}`} alt="contact button" key={"contact button"} onClick={() => { handleNavigateHeader("cover-your-track") }}>

                                {/* <div className={`Top-Header-Navigation-Icon ${selectedPage === 'cover-your-track' ? "Selected" : ""}`} key={"1cover-your-track button"}>
                                <CgErase />
                            </div> */}
                                <span className="color-pink text-decoration-none " key={"2contact button"}>{t('cover-your-track')}</span>

                            </div>
                        </Link>
                       
                    </div>

                    :
                    <span className="width-6-rem height-1-rem background-color-light-pink margin-2-rem" onClick={() => { handleHeaderOpen(!headerOpen) }}>
                        <Selection values={headerSelectionOptions} label={t("resources")} open={headerOpen} handleOpen={handleHeaderOpen} setCurrentValue={setHeaderCurrentValue} currentValue={headerCurrentValue} onChange={handleHeaderSelectionChange} itemStyle="Header-Item-Style" labelStyle={"Header-Label-Style"} containerStyle={"Header-Container-Style"} dropdownStyle="Header-Dropdown-Style" />
                    </span>
                }
                <div className="Language-Icons-Container">
                    <div className="Language-Selection">
                        <div className={`Language-Option ${language === 'en' ? "Selected-Language" : ""} cursor-pointer font-size-16-px`} onClick={() => { changeLanguage('en') }}>English</div>
                        <div className="Separator" alt="separator">
                            <TbMinusVertical />
                        </div>
                        <div className={`Language-Option ${language === 'fr' ? "Selected-Language" : ""} cursor-pointer font-size-16-px`} onClick={() => { changeLanguage('fr') }}>Français</div>
                    </div>
                    {/* <div className="Header-Icons">
                        <div className="Header-Icon" alt="facebook button" onClick={() => window.open("https://www.facebook.com")}>
                            <FiFacebook />
                        </div>
                        <div className="Header-Icon" alt="twitter button" onClick={() => window.open("https://www.twitter.com")}>
                            <FiTwitter />
                        </div>
                        <div className="Header-Icon" alt="instagram button" onClick={() => window.open("https://www.instagram.com")}>
                            <FiInstagram />
                        </div>
                        <div className="Header-Icon" alt="mail button" onClick={() => window.open("mailto: george.afterligt@gmail.com")}>
                            <AiOutlineMail />
                        </div>
                    </div> */}
                </div>
            </div>

        </div>
    );
}

export default Header;
