import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import toolkitImgEng from "../../../assets/images/resources/toolkit/Toolkit EN.png";
import toolkitImgFre from "../../../assets/images/resources/toolkit/Toolkit FR.png";

import challengingAndChangingPdf from "../../../assets/pdf/Challenging and Changing Mainstream Narratives on FGM - In Conversation with Samiya Abdi.pdf";
import imNotBrokenPdf from "../../../assets/pdf/I am not broken - An Interview with Warda.pdf";
import IntersectionalityPdf from "../../../assets/pdf/Intersectionality in Action - Learning with and from Survivors of FGM.pdf"
import birthworkersPdf from "../../../assets/pdf/Birthworkers _ FGM_C - At the Intersection of Survivor _ Advocate.pdf"
import comingOutTwicePdf from "../../../assets/pdf/Coming out twice - In conversation with Fode.pdf"
import toolkitEnglishPdf from "../../../assets/pdf/Flourish Toolkit (English).pdf"
import toolkitFrenchPdf from "../../../assets/pdf/Flourish Toolkit (French).pdf"
import mediaStatementPolicyPdf from "../../../assets/pdf/Flourish - Media Statement and Policy.pdf";
import mysqlServices from "../../../services/mysqlServices";

import "./toolkit.css";

function ToolKit(params) {
    const canLoad = useRef(true);
    const { t, i18n } = useTranslation();

    const [showQuestions, setShowQuestions] = useState(false)
    const [surveyOpen, setSurveyOpen] = useState(true)
    const [thankyouMessage ,setThankyouMessage] = useState(false);


    useEffect(() => {
        if (canLoad.current) {
            //console.log(i18n.language)
        } else {

        }
    }, [])

    const handleYesClick = () => {
        setShowQuestions(true);
    }

    const handleNoClick = () => {
        setSurveyOpen(false);
    }

    const handleSurveySubmit = () => {
        if(Object.keys(answers)===0){
            return;
        }else {
            mysqlServices.uploadSurveyQuestion(answers).then(data => {
                //console.log(data)
                setSurveyOpen(false)
                setThankyouMessage(true)
                setTimeout(()=>{setThankyouMessage(false)}, 4000)
            }).catch(err => {
                console.log(err);
            })
        }
    }

    const survay = [
        { 
            "question": "Which of the following groups do you identify with the most?",
            "options" : [
                "Survivors or women with lived individual experience of FGM/C",
                "Women from FGM/C impacted communities",
                "Health or community service providers",
                "Educators or community facilitators",
                "Policymakers or researchers",
                "Other"
            ]
        },
        {
            "question": "Did the information in the Toolkit increase your cultural competence and sensitivity and fill knowledge gaps about FGM/C?",
            "options": [
                "Yes",
                "No",
                "Not Sure"
            ]
        },
        {
            "question": "How do you intend to use the Toolkit?",
            "options": [
                "In my work",
                "For my self-development",
                "Other"
            ]
        },
        {
            "question": "How would you rate the overall value of the Toolkit?",
            "options": [
                "Very valuable",
                "Valuable",
                "Neutral",
                "Somewhat valueable",
                "Not valueable at all"
            ]
        },
        {
            "question": "How likely are you to recommend this Toolkit to a friend or colleague?",
            "options": [
                "Very likely",
                "Somewhat likely",
                "Not likely"
            ]
        },
        {
            "question": "How would you rate your overall experience on our site today?",
            "options": [
                "Very satisfied",
                "Satisfied",
                "Neither",
                "Dissatisfied",
                "Very dissatisfied"
            ]
        }
    ]

    const [answers, setAnswers] = useState({});

    const handleAnswer = (question, answer)=> {
        let tempAnswers = {...answers}
        tempAnswers[question] = answer
        //console.log(tempAnswers)
        setAnswers(tempAnswers);
    }

    return (
        <div className="display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-1-rem column-gap-1-rem">
            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-22-px bold">{t("resources-page.toolkit.header1")}</span>
                <span className="">{t("resources-page.toolkit.text1")}</span>
            </div>
            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.toolkit.header2")}</span>
                <span className="display-flex width-fit flex-wrap column-gap-1-rem">
                    <img src={toolkitImgEng} onClick={() => { window.open(toolkitEnglishPdf) }} className={"cursor-pointer width-15-rem hover-border-dotted"} alt="complete pdf toolkit" />
                    <img src={toolkitImgFre} onClick={() => { window.open(toolkitFrenchPdf)}} className={"cursor-pointer width-15-rem hover-border-dotted"} alt="complete pdf toolkit" />
                </span>
            </div>

            <div className="display-flex flex-direction-column  flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.toolkit.header3")}
                </span>
                <ul>
                    <li>
                        <span className="cursor-pointer hover-background-color-light-pink" onClick={() => { window.open(mediaStatementPolicyPdf) }}>{t("resources-page.toolkit.text3.1.header")}</span>
                    </li>
                    <li>
                        <span className="">{t("resources-page.toolkit.text3.2.header")}</span>
                        <ul>
                            <li className="width-fit cursor-pointer hover-background-color-light-pink" onClick={() => { window.open(birthworkersPdf) }}>
                                {t("resources-page.toolkit.text3.2.text1")}
                            </li>
                            <li className="width-fit cursor-pointer hover-background-color-light-pink" onClick={() => { window.open(challengingAndChangingPdf) }}>
                                {t("resources-page.toolkit.text3.2.text2")}
                            </li>
                            <li className="width-fit cursor-pointer hover-background-color-light-pink" onClick={() => { window.open(comingOutTwicePdf) }}>
                                {t("resources-page.toolkit.text3.2.text3")}
                            </li>
                            <li className="width-fit cursor-pointer hover-background-color-light-pink" onClick={() => { window.open(imNotBrokenPdf) }}>
                                {t("resources-page.toolkit.text3.2.text4")}
                            </li>
                            <li className="width-fit cursor-pointer hover-background-color-light-pink" onClick={() => { window.open(IntersectionalityPdf) }}>
                                {t("resources-page.toolkit.text3.2.text5")}
                            </li>
                        </ul>
                    </li>
                    
                </ul>
                
            </div>

            <div className="display-flex flex-direction-column row-gap-0-5-rem column-gap-0-5-rem flex-wrap">
                <span className="font-size-18-px bold">{t("resources-page.toolkit.header4")}</span>
                <span className="">{t("resources-page.toolkit.text4.1")}</span>
                <span className="">{t("resources-page.toolkit.text4.2")}</span>
                <span className="bold">{t("resources-page.toolkit.text4.3")}</span>
            </div>

            {surveyOpen ? <div className="Survey">
                <span className="bold font-size-15-px">Your Opinion Matters a Lot to Us!</span>
                <span>Are you interested in answering a few questions? Won't be long!</span>
                <span className="Option" onClick={()=> handleYesClick()}>Yes</span>
                <span className="Option" onClick={() => handleNoClick()}>No</span>

                {showQuestions? <div>
                    <ol>
                        {survay.map((item, index) =>
                            <li key={item.question+index} className="">
                                <div>
                                    <span>{item.question}</span>
                                    {item.options.map((option, index) =>
                                        option === "Other" ?
                                            <div>
                                                <input type="radio" id={option + index} name={item.question} className="margin-right-0-5-rem"></input>
                                                <label for={option + index}>{option}</label>
                                                <input type="text" id={option + index} name={item.question} className="margin-left-0-5-rem" onChange={(e) => handleAnswer(item.question, e.target.value)} ></input>

                                            </div> :
                                            <div>
                                                <input type="radio" id={option + index} name={item.question} className="margin-right-0-5-rem" onChange={()=> handleAnswer(item.question, option)}></input>
                                                <label for={option + index}>{option}</label>
                                            </div>

                                    )}

                                </div>
                              
                                
                                
                            </li>
                        )}
                    </ol>
                    <button className="Option" onClick={()=> handleSurveySubmit()}>{t("Submit")}</button>

                </div> : null}

            </div> : null}
            {
                thankyouMessage? 
                <div className="background-color-light-pink width-fit">
                    {t("thankYou")}
                </div> : null
            }


        </div>
    )
}
export default ToolKit;