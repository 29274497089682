import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import blogPlaceHolderImg from "../../../assets/images/blogs/placeholder-image.jpg";

import "./blogs.css";
import mysqlServices from "../../../services/mysqlServices";

export default function Blogs(params) {
    const loaded = useRef(false);
    const { t, i18n } = useTranslation();
    const [allBlogs, setAllBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);


    useEffect(() => {
        if (!loaded.current) {
            loaded.current = true;
            mysqlServices.getAllBlogs().then(d => {
                setAllBlogs(d?.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }, []);

    function getTimeFromMysqlDatetime(time) {
        if(typeof time !== "string"){
            if (time?.toLocaleDateString()){
                return time?.toLocaleDateString()
            }
            return time;
        }
        // Split timestamp into [ Y, M, D, h, m, s ]
        var t = time.split(/[- : T .]/);


        // Apply each element to the Date function
        var d = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));

        return d.toLocaleDateString();
    }

    const getContentBasedOnLang = (item)=> {
        if (i18n.language === "fr"){
            if(item.content_french){
                return item.content_french
            } else if (item.content_english){
                return item.content_english
            }else{
                return ""
            }
        } else if (item.content_english) {
            return item.content_english
        } else {
            return ""
        }
    }

    return (
        <div className="display-flex flex-direction-column flex-wrap min-width-15-rem row-gap-3-rem column-gap-3-rem">
            <div className=""><span className="font-size-35-px bold">{t(`Blog`)}:</span> <span className="font-size-30-px bold">{t("Gender-Based Violence Prevention and care")}</span></div>
            <div className={`display-flex flex-wrap row-gap-2-rem column-gap-2-rem ${selectedBlog ? "" : "flex-direction-column"}`}>
                {selectedBlog ?
                    <div className=" max-width-600-px display-flex flex-direction-column flex-wrap row-gap-1-rem column-gap-1-rem">
                        <span className="bold font-size-24-px max-inline-size-600-screen-control word-wrap-break-word">{i18n.language === "fr" ? selectedBlog?.title_french : selectedBlog?.title_english}</span>
                        <span className="bold font-size-13-px">{getTimeFromMysqlDatetime(selectedBlog?.creation_date)} | {selectedBlog?.author}</span>
                        <img alt="blog" className="width-85-percent max-width-575-px" src={selectedBlog?.image_link ?? blogPlaceHolderImg} />
                        <div className="max-inline-size-600-screen-control word-wrap-break-word display-flex flex-direction-column row-gap-2-rem">
                            {getContentBasedOnLang(selectedBlog).split("{#}").map(parag =>
                                <span className="max-inline-size-600-screen-control word-wrap-break-word">{parag}</span>
                            )}
                        </div>
                  
                    </div> : null
                }
                {allBlogs?.length ?
                    <div className={`flex-grow-1 display-flex flex-direction-column flex-wrap row-gap-1-rem column-gap-1-rem ${selectedBlog ? "width-30-percent" : ""}`}>
                        {allBlogs?.filter(item => selectedBlog ? JSON.stringify(item) !== JSON.stringify(selectedBlog) : item).map((blogItem, index) =>
                            <div key={"blog" + index} className="display-flex flex-direction-column flex-wrap row-gap-0-5-rem column-gap-0-5-rem">
                                <div className="display-flex row-gap-1-rem column-gap-1-rem align-items-center">
                                    <img alt="blog" className="width-5-rem" src={blogItem?.image_link ?? blogPlaceHolderImg} />
                                    <div className="display-flex flex-direction-column flex-wrap ">
                                        <span className="bold font-size-18-px">{i18n.language === "fr" ? blogItem?.title_french : blogItem?.title_english}</span>
                                        <div className="display-flex flex-wrap">
                                            <span className="bold font-size-13-px">{getTimeFromMysqlDatetime(blogItem?.creation_date)}</span>
                                            <span className="bold font-size-13-px margin-x-2-px">|</span>
                                            <span className="bold font-size-13-px">{t("by")} {blogItem?.author}</span>
                                        </div>
                                        <span onClick={() => { setSelectedBlog(blogItem); }} className="color-orange underline cursor-pointer">{t("Read More")}</span>
                                    </div>
                                </div>
                                {index !== allBlogs.length - 1 ?
                                    <div className="display-flex flex-direction-column flex-wrap">
                                        <span className="Blog-Top-Line"></span>
                                        <span className="Blog-Bottom-Line"></span>
                                    </div>
                                    : null}
                            </div>
                        )}
                    </div>
                    : <span className="bold">{t("No blogs are shared yet.")}</span>}
            </div>

        </div>
    )
}
