import React, { useState, useRef, useEffect } from "react";
import DisplayContent from "./displayMedia";
import mysqlServices from "../../services/mysqlServices";



function ImagesPage(params) {
    const canLoad = useRef(true);
    const [loading, setLoading] = useState(false);
    const [imagesArray, setImagesArray] = useState([]);

    useEffect(() => {
        // console.log('ds')
        if (canLoad.current) {
            canLoad.current = false;
            //load the images from the databases
            //set loading true
            //when you finish set loading false
            setLoading(true);
            mysqlServices.getImages().then(data => {
                // console.log(data)
                setImagesArray(data.data);
            }).catch(err => {
                console.log(err);
            })

        } else {

        }
    }, [])


    return (
        <div>
        <p>Images</p>
        <div className="display-flex row-gap-1-rem column-gap-1-rem">
            
            {imagesArray.length > 0 ? 
            imagesArray.map(item => 
                (<img key={item.src} src={item.src} alt={item.src} width={200} height={200} />)
                )
            : null    
            }
        </div>
        </div>
    )
}
export default ImagesPage;