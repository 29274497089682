import React, { useState, useRef, useEffect } from "react";
// import video from "../../../assets/videos/Threats Posed by COVID-19 on Gender Equality.mp4";

// import {DefaultPlayer as Video} from "react-html5video";
import 'react-html5video/dist/styles.css';

import "./webinars.css";

import { useTranslation } from 'react-i18next';
import mysqlServices from "../../../services/mysqlServices";
import YoutubeEmbed from "../../tools.component/youtubeEmbed.component";

function Webinars(params) {
    const canLoad = useRef(true);
    const [linkss, setLinkss] = useState([]);
    const { t, i18n } = useTranslation();
    useEffect(() => {
        if (canLoad.current) {
            mysqlServices.getResourcesWebinars().then(data => {
                //console.log(data.data)
                let lin = data.data;
                setLinkss(lin);
            }).catch(err => {
                console.log(err);
            })

        } else {

        }
    }, [])


    return (
        <div className='display-flex flex-direction-column flex-wrap  min-width-15-rem row-gap-2-rem column-gap-2-rem overflow-y-hidden'>


            {linkss.length > 0 ?
                linkss.map(links =>
                    <div className="display-flex flex-direction-column coloumn-gap-0-5-rem row-gap-0-5-rem">
                        <span className='font-size-20-px bold'>{i18n.language === "fr" ? links.header_french : links.header_english}</span>
                        <div className="">
                            {/* <Video loop>
                            <source src={video} type="video/mp4" />
                         </Video> */}

                            {links.type === "youtube" ?
                                <YoutubeEmbed title={i18n.language === "fr" ? links.header_french : links.header_english} embedId={links.link} videoStyle="video-responsive" />
                                :
                                links.type === "vimeo" ?
                                    <span>
                                        <iframe src={links.link} title="vimeo video" className="video-responsive" height="auto" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                                    </span>
                                    : null
                            }
                        </div>

                        <span className=''>{i18n.language === "fr" ? links.paragraph_french : links.paragraph_english}</span>
                    </div>
                )

                : null
            }

        </div>
    )
}
export default Webinars;